import {Component, OnInit} from '@angular/core';
import {MapBase} from "../../pagebase/mapbase";

@Component({
  selector: 'app-daily-user-track-detail',
  templateUrl: './daily_user_tracking_details.component.html',
  styleUrls: ['./daily_user_tracking_details.component.scss']
})

export class DailyUserTrackingDetailComponent extends MapBase implements OnInit {

  chartData = [
    {
      name: "Visited",
      value: 0,
      extra: {
        code: "V"
      }
    },
    {
      name: "Productive",
      value: 0,
      extra: {
        code: "P"
      }
    },
    {
      name: "Not Visited",
      value: 0,
      extra: {
        code: "NV"
      }
    }];
  view: any[] = [500, 200];
  showAlertCard = false;
  showActivityCard = false;
  markerData = [];
  retailerData = [];
  trackData = [];
  summaryData: any = {};
  attendanceData: any = {};
  alertData = [];
  activityData: any = [];
  legendData: any = [
    {
      image: '/assets/map/grey.svg',
      name: "Beat Jump"
    },
    {
      image: '/assets/map/warning_red.svg',
      name: "Not Visited"
    },
    {
      image: '/assets/map/green_tick.svg',
      name: "Completed"
    },
    {
      image: '/assets/map/warning_orange.svg',
      name: "Alert"
    },
    {
      image: '/assets/map/green.svg',
      name: "Productive"
    },
    {
      image: '/assets/map/start.svg',
      name: "Start"
    },
    {
      image: '/assets/map/end.svg',
      name: "End"
    }
  ];
  chartHeader = '';
  totalDistance = 0;
  totalTime;
  startTime;
  endTime;
  searchableFilter: any = {
    name: "User",
    bindLabel: "name",
    bindValue: "id",
    value: 0,
    values: []
  };
  showAlertMarkers = true;
  showRetailerMarkers = true;
  beatJumpCount = 0;


  ngOnInit(): void {
    this.sharedService.setHeader('Daily User Tracking');
    this.API_URL = '/api/retail_so_track_logs/get_user_track_activities';
    this.dateSelectMode = 'single';
    this.loadCacheValues();
    this.searchableFilter.values = this.activeItems;
    this.popuplateSearchableFilter();
    this.loadMapData();
  }

  changeDate(dt) {
    this.myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
  }

  fetchMembers() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    this.apiService.post('/api/res_users/get_members', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.searchableFilter.values = res.results.data;
          this.popuplateSearchableFilter();
          this.loadMapData();
        }
      });
  }

  setValuestoDefault() {
    this.dataLoaded = false;
    this.chartData = [];
    this.summaryData = [];
    this.markerData = [];
    this.activityData = [];
    this.trackData = [];
    this.alertData = [];
    this.retailerData = [];
    this.totalDistance = 0;
    this.totalTime = 0;
    this.chartHeader = '';
    this.beatJumpCount = 0;
    this.startTime = '';
    this.endTime = '';
  }

  postProcessData() {
    if (this.apiData.summaryData) {
      this.summaryData = this.apiData.summary;

      if (this.summaryData?.beats && this.summaryData?.beats.length > 0) {
        this.chartHeader = this.summaryData.beats[0].name;
      }
    }
    if (this.apiData.att && this.apiData.att.length > 0) {
      this.attendanceData = this.apiData.att[0];
      if (this.attendanceData.att_time) {
        this.startTime = this.datePipe.transform((new Date(this.attendanceData.att_time)).toString(), 'yyyy-MM-dd HH:mm:ss');
      }
      if (this.attendanceData.eod_time) {
        this.endTime = this.datePipe.transform((new Date(this.attendanceData.eod_time)).toString(), 'yyyy-MM-dd HH:mm:ss');
        const diff = new Date(this.endTime).getTime() - new Date(this.startTime).getTime(); //in ms
        let msec = diff;
        const hh = Math.floor(msec / 1000 / 60 / 60);
        msec -= hh * 1000 * 60 * 60;
        const mm = Math.floor(msec / 1000 / 60);
        msec -= mm * 1000 * 60;
        const ss = Math.floor(msec / 1000);
        msec -= ss * 1000;
        this.totalTime = ('0' + hh).slice(-2) + 'hr ' + ('0' + mm).slice(-2) + 'mins';
      } else {
        this.endTime = '';
        if (this.startTime) {
          this.totalTime = 'In Progress';
        } else {
          this.totalTime = 'Not Started';
        }
      }
    }
    this.markerData = [];
    if (this.apiData.track && this.apiData.track.length > 0) {
      let distance_set = false;
      const trackid_set = new Set();
      if (this.apiData.track[0].distance_travelled) {
        distance_set = true;
        this.totalDistance = this.apiData.track[0].distance_travelled;
      }
      this.apiData.track.forEach(data => {
        if (!distance_set && !trackid_set.has(data.track_id) && data.distance && parseFloat(data.distance) > 0) {
          this.totalDistance += parseFloat(data.distance);
          trackid_set.add(data.track_id);
        }
        if (data.log && data.log.length > 0) {
          data.log.forEach(tracklog => {
            if (parseFloat(tracklog.lat) > 0 && parseFloat(tracklog.lng) > 0) {
              this.trackData.push({
                t: tracklog.t,
                time: this.datePipe.transform((new Date(tracklog.t) + 'UTC').toString(), 'yyyy-MM-dd HH:mm:ss'),
                lat: tracklog.lat,
                long: tracklog.lng
              });
            }
          });
        }
      });
      this.trackData[0]['icon'] = 'start_marker';
      this.trackData[this.trackData.length - 1]['icon'] = 'end_marker';
      this.markerData.push(this.trackData[0]);
      this.markerData.push(this.trackData[this.trackData.length - 1]);
      this.AlertCheck();
      if (this.totalDistance && this.totalDistance > 0) {
        this.totalDistance = this.totalDistance / 1000;
        this.totalDistance = parseFloat(this.totalDistance.toFixed(2));
      } else {
        this.totalDistance = 0;
      }
    }
    if (this.apiData.ret && this.apiData.ret.length > 0) {
      this.retailerData = this.apiData.ret;
      let visited = 0;
      let productive = 0;
      let not_visited = 0;
      let unplanned_visit  = 0;
      let obj = this.retailerData.filter(x => x.status === 'V');
      if (obj) {
        visited = obj.length;
      }
      obj = this.retailerData.filter(x => x.status === 'P');
      if (obj) {
        productive = obj.length;
      }
      obj = this.retailerData.filter(x => x.status === 'NV');
      if (obj) {
        not_visited = obj.length;
      }
      obj = this.retailerData.filter(x => x.status === 'UV');
      if (obj) {
        unplanned_visit = obj.length;
      }
      this.beatJumpCount = unplanned_visit;
      this.chartData = [
        {
          name: "Visited (" + visited + ")",
          value: visited,
          extra: {
            code: "V"
          }
        },
        {
          name: "Productive (" + productive + ")",
          value: productive,
          extra: {
            code: "P"
          }
        },
        {
          name: "Not Visited (" + not_visited + ")",
          value: not_visited,
          extra: {
            code: "NV"
          }
        }];
      this.retailerData.forEach(data => {
        if (data['status']) {
          if (data['status'] === 'P') {
            data['icon'] = 'green_marker';
          } else if (data['status'] === 'V') {
            data['icon'] = 'blue_marker';
          } else if (data['status'] === 'NV') {
            data['icon'] = 'red_warning_marker';
          } else if (data['status'] === 'UV') {
            data['icon'] = 'grey_marker';
          } else {
            data['icon'] = 'green_marker';
          }
        } else {

        }
        if (data.device_time) {
          data['time'] = this.datePipe.transform((new Date(data.device_time + ' UTC')).toString(), 'hh:mm a');
        }
      });
    } else {
      this.chartData = [
        {
          name: "Visited (0)",
          value: 0,
          extra: {
            code: "V"
          }
        },
        {
          name: "Productive (0)",
          value: 0,
          extra: {
            code: "P"
          }
        },
        {
          name: "Not Visited (0)",
          value: 0,
          extra: {
            code: "NV"
          }
        }];
    }
    if (this.apiData.act && this.apiData.act.length > 0) {
      this.apiData.act.forEach(data => {
        data['icon'] = 'green_marker';
        if (data.device_time) {
          data['time'] = this.datePipe.transform((new Date(data.device_time + ' UTC')).toString(), 'hh:mm a');
        }
        this.markerData.push(data);
        /*if (data['beat'] && data['beat'] !== this.chartHeader) {
          data['icon'] = 'grey_marker';
          this.beatJumpCount++;
        }*/
      });
      this.activityData = [];
      const unique_dt = this.sharedService.unique(this.apiData.act, ['device_time']);
      unique_dt.forEach(uq => {
        const found = this.apiData.act.filter(x => x.device_time === uq['device_time']);
        if (found.length > 1) {
          this.activityData.push({
            activity: found[0].activity,
            activity_type: found[0].activity_type,
            time: found[0].time,
            ret_id: found[0].ret_id,
            ret_name: found[0].ret_name,
            ret_code: found[0].ret_code,
            activities: found
          });
        } else {
          this.activityData.push(found[0]);
        }
      });
      console.log(this.activityData);
      console.log(this.trackData);
      console.log(this.markerData);
    } else {
      this.activityData = [];
    }
    this.dataLoaded = true;
  }


  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  AlertCheck() {
    this.alertData = [];
    if (this.trackData && this.trackData.length > 0) {
      let distance;
      let timediff;
      let speed;
      let lat = this.trackData[0].lat;
      let long = this.trackData[0].long;
      let starttime = this.trackData[0].t;
      for (let i = 1; i < this.trackData.length; i++) {
        if (this.trackData[i].lat !== lat || this.trackData[i].long !== long) {
          lat = this.trackData[i].lat;
          long = this.trackData[i].long;
          timediff = this.trackData[i].t - starttime; //i
          timediff = Math.floor(timediff / 1000 / 60 / 60);
          starttime = this.trackData[i].t;
        }
        if (timediff > 1) {
          this.alertData.push(
            {
              icon: 'alert_marker',
              type: 'time_spent',
              lat: this.trackData[i].lat,
              long: this.trackData[i].long,
              time: this.trackData[i].time
            }
          );
        } else {
          distance = this.getDistanceFromLatLonInKm(this.trackData[i - 1].lat, this.trackData[i - 1].long, this.trackData[i].lat, this.trackData[i].long);
          timediff = this.trackData[i].t - this.trackData[i - 1].t; //in ms
          timediff = Math.floor(timediff / 1000 / 60 / 60);
          if (timediff > 0) {
            speed = distance / timediff;
            console.log(new Date(this.trackData[i].time));
            if (speed > 100) {
              this.alertData.push(
                {
                  icon: 'alert_marker',
                  type: 'speed',
                  lat: this.trackData[i].lat,
                  long: this.trackData[i].long,
                  time: this.trackData[i].time
                }
              );
            }
          }
        }
      }
      console.log(this.alertData);
    }

  }

  onMapReady(event) {
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    this.agm_map = event;
    let boundData = [];
    if (this.trackData && this.trackData.length > 0) {
      boundData = this.trackData;
    } else if (this.markerData && this.markerData.length > 0) {
      boundData = this.markerData;
    } else if (this.retailerData && this.retailerData.length > 0) {
      boundData = this.retailerData;
    } else {
      boundData = [];
    }
    for (const mm of boundData) {
      if (mm['lat'] && mm['long']) {
        bounds.extend(new google.maps.LatLng(mm['lat'], mm['long']));
      }
    }
    this.agm_map.fitBounds(bounds);
  }

  alertCardClick() {
    this.showAlertCard = !this.showAlertCard;
  }

  activityCardClick() {
    this.showActivityCard = !this.showActivityCard;
  }

}
