import {Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {BnNgIdleService} from 'bn-ng-idle';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {ApiService} from './_services/api.service';
import {environment} from '../environments/environment';
import {SharedService} from './_services/shared_service';
import {DateTimeAdapter} from 'ng-pick-datetime';
import {UpdateService} from './_services/update.service';
import {LocalforageService} from "./_services/localforage.service";
import {DomSanitizer} from "@angular/platform-browser";
import {DatePipe} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import * as JSZip from "jszip";
import html2canfast from "html2canfast";
import {jsPDF} from "jspdf";
import {saveAs} from 'file-saver';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('interPrint') public interPrint: ElementRef;
  @ViewChild('customPrintClick') customPrintClick: ElementRef<HTMLElement>;
  title = 'sales';
  url;
  showHead = false;
  disabled = false;
  collapse_disabled = false;
  header_title = '';
  public role_features = [];
  public token;
  print_details = [];
  userData = {
    access_token: null
  };
  public order_templ_config;
  public invoice_templ_config;
  public templ_config;
  public orderTemplate;
  public invoiceTemplate;
  public template;
  public selectedTemplate = null;
  gst_template;
  non_gst_template;
  printType = 'Order';
  public completed = 0;
  public total = 0;
  public progress = 0;
  public zip_order = null;
  featureMenuv2 = false;
  pageReady = true;
  public currentApplicationVersion = environment.appVersion;
  constructor(private bnIdle: BnNgIdleService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private dateTimeAdapter: DateTimeAdapter<any>,
              private sharedService: SharedService,
              private apiService: ApiService,
              private sw: UpdateService,
              private domSanitizer: DomSanitizer,
              public datePipe: DatePipe,
              public toastr: ToastrService,
              private _localforageService: LocalforageService) { // initiate it in your component constructor
    this.bnIdle.startWatching(60 * 60 * 8).subscribe((res) => {
      if (res) {

        console.log('session expired');
        this.sharedService.clearCache();
        this._localforageService.DELETE_ALL();
        this.url = localStorage.getItem('url');
        this.router.navigate([this.apiService.redirect_url + '']);
      }
    });

    // check the service worker for updates
    this.sw.checkForUpdates();
    const logged_in = (localStorage.getItem('logged_in') === "true");
    /* if (!logged_in) {
       console.log('not logged in');
       this.sharedService.clearCache();
       this._localforageService.DELETE_ALL();
       this.url = localStorage.getItem('url');
       this.router.navigate([this.apiService.redirect_url + '']);
     } else {
       localStorage.setItem('collapse_disabled', this.collapse_disabled.toString());
       localStorage.setItem('profile_disabled', this.disabled.toString());
       localStorage.setItem('already_logged', false.toString());
       dateTimeAdapter.setLocale('en-IN');
       if (environment.instanceURL !== 'from_login') {
         this.apiService.api_url = environment.instanceURL;
       } else {
         this.apiService.api_url = localStorage.getItem('private_url');
       }
       console.log('component', this.apiService.api_url);
       this.apiService.redirect_url = '';
     }*/
    localStorage.setItem('collapse_disabled', this.collapse_disabled.toString());
    localStorage.setItem('profile_disabled', this.disabled.toString());
    localStorage.setItem('already_logged', false.toString());
    dateTimeAdapter.setLocale('en-IN');
    if (environment.instanceURL !== 'from_login') {
      this.apiService.api_url = environment.instanceURL;
    } else {
      this.apiService.api_url = localStorage.getItem('private_url');
    }
    console.log('component', this.apiService.api_url);
    this.apiService.redirect_url = '';
    this.featureMenuv2 = this.sharedService.ACLcheck('feature_menuv2');
    this.featureMenuv2 = true;
  }

  profClick() {

    this.disabled = (localStorage.getItem('profile_disabled') === 'true');
    this.disabled = !this.disabled;
    localStorage.setItem('profile_disabled', this.disabled.toString());
    if (this.disabled) {
      if (screen.width > 991) {
        this.elRef.nativeElement.querySelector('#profile').style.width = '20%';
      } else {
        this.elRef.nativeElement.querySelector('#profile').style.width = '80vw';
      }
    } else {
      this.elRef.nativeElement.querySelector('#profile').style.width = '0%';
    }
    this.renderer[this.disabled ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.profilebar'), 'active');
  }

  siderCollapse() {
    console.log(this.collapse_disabled);
    this.collapse_disabled = !this.collapse_disabled;
    localStorage.setItem('collapse_disabled', this.collapse_disabled.toString());
    this.renderer[this.collapse_disabled ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.sidebar'), 'active');
    const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li label');
    const inside_collapse = this.collapse_disabled;
    const inside_renderer = this.renderer;
    if (this.collapse_disabled) {
      setTimeout(function() {
        for (let i = 0; i < elements.length; i++) {
          inside_renderer[inside_collapse ? 'removeClass' : 'addClass'](elements[i], 'hide');
        }
      }, 300);
    } else {
      for (let i = 0; i < elements.length; i++) {
        this.renderer[this.collapse_disabled ? 'removeClass' : 'addClass'](elements[i], 'hide');
      }
    }
    this.renderer[this.collapse_disabled ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  changeActive(event: string) {
    // console.log("changeActive");
    // console.log(this.collapse_disabled);
    if (this.collapse_disabled) {
      this.siderCollapse();
    }

    const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
    const insideThis = this;
    if (elements.length === 0) {
      setTimeout(() => {
        const elements1 = insideThis.elRef.nativeElement.querySelectorAll('.sidebar ul li');
        for (let i = 0; i < elements1.length; i++) {
          if (elements1[i].firstChild.lastChild.innerHTML === event) {
            insideThis.renderer.addClass(elements1[i], 'active');
          } else {
            insideThis.renderer.removeClass(elements1[i], 'active');
          }
        }
      }, 100);
    } else {
      for (let i = 0; i < elements.length; i++) {
        if (event === 'dms_orders' || event === 'dms_orders_p' ||
          event === 'dms_invoice' || event === 'dms_invoice_p' || event === 'dms_s_return' || event === 'dms_sec_return_inv' ||
          event === 'dms_p_return') {
          if (elements[i].id === event) {
            this.renderer.addClass(elements[i], 'active');
          } else {
            this.renderer.removeClass(elements[i], 'active');
          }
        } else {
          if (elements[i].firstChild.lastChild.innerHTML === event) {
            this.renderer.addClass(elements[i], 'active');
          } else {
            this.renderer.removeClass(elements[i], 'active');
          }
        }
      }
    }
  }

  fetchMenus(route) {
    let menuList = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
    if (menuList.length === 0) {
      setTimeout(() => {
        menuList = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
        this.changeActiveV2(menuList, route);
      }, 100);
    } else {
      this.changeActiveV2(menuList, route);
    }
  }

  changeActiveV2(menuList, route) {
    if (route.includes('dash') || route.includes('boards')) {
      this.clearActive(menuList);
      this.renderer.addClass(document.getElementById('dashboard'), 'active');
      $("#img_dashboard").attr('src', this.getIcon("dashboard_sel"));
    } else if (route.includes('reports')) {
      this.clearActive(menuList);
      this.renderer.addClass(document.getElementById('report'), 'active');
      this.renderer.addClass( document.getElementById('a_report'), 'active');
      $("#img_report").attr('src', this.getIcon("report_sel"));
    } else if (route.includes('menu_detail')) {
      this.clearActive(menuList);
      this.renderer.addClass(document.getElementById('more'), 'active');
      this.renderer.addClass( document.getElementById('a_more'), 'active');
      $("#img_more").attr('src', this.getIcon("more_sel"));
    } else {
      let activeID = '';
      let type = '';
      let menuItem: any = {};
      let subMenuItem: any = {};

      for (let i = 0; i < this.sharedService.menuList.length; i++) {
        if (this.sharedService.menuList[i].route && this.sharedService.menuList[i].route === route) {
          menuItem = this.sharedService.menuList[i];
          type = 'menu';
          break;
        }
        if (this.sharedService.menuList[i].sub_menu && this.sharedService.menuList[i].sub_menu.length > 0) {
          for (let j = 0; j < this.sharedService.menuList[i].sub_menu.length; j++) {
            if (this.sharedService.menuList[i].sub_menu[j].route && this.sharedService.menuList[i].sub_menu[j].route === route) {
              subMenuItem = this.sharedService.menuList[i].sub_menu[j];
              menuItem = this.sharedService.menuList[i];
              type = 'submenu';
              break;
            }
          }
        }
      }
      if (menuItem && menuItem.name) {
        if (type === 'menu') {
          activeID = this.constructID(menuItem.name);
        } else {
          activeID = this.constructID(menuItem.name + '_' + subMenuItem.name);
        }
        this.clearActive(menuList);
        this.setActive(menuList, menuItem, subMenuItem, activeID, type);
      } else {
        let activeSideMenu = localStorage.getItem('activeSideMenu');
        if (activeSideMenu) {
          activeSideMenu = JSON.parse(activeSideMenu);
          if (activeSideMenu['activeID']) {
            this.clearActive(menuList);
            this.setActive(menuList, activeSideMenu['menuItem'], activeSideMenu['subMenuItem'], activeSideMenu['activeID'], activeSideMenu['type']);
          }
        }
      }
    }
  }

  setActive(menuList, menuItem, subMenuItem, activeID, type) {
    if (activeID) {
      const inThis = this;
      const activeSideMenu = {
        menuItem,
        subMenuItem,
        activeID,
        type
      }
      localStorage.setItem('activeSideMenu', JSON.stringify(activeSideMenu));
      menuList.forEach(item => {
        if (item.id === activeID) {
          inThis.renderer.addClass(item, 'active');
          inThis.renderer.addClass(document.getElementById('a_' + item.id), 'active');
          if (menuItem.icon && menuItem.icon !== '') {
            $("#img_" + item.id).attr('src', inThis.getIcon(menuItem.icon + "_sel"));
          } else {
            $("#img_" + item.id).attr('src', '/assets/menu/sales_sel.svg');
          }
          if (subMenuItem.icon && subMenuItem.icon !== '') {
            $("#img_" + item.id).attr('src', inThis.getIcon(subMenuItem.icon + "_sel"));
          } else {
            $("#img_" + item.id).attr('src', '/assets/menu/sales_sel.svg');
          }
        }
      });
      if (type === 'submenu') {
        menuList.forEach(item => {
          if (item.id === inThis.constructID(menuItem.name)) {
            inThis.renderer.addClass(item, 'active');
            inThis.renderer.addClass(document.getElementById('a_' + item.id), 'active');
            if (menuItem.icon && menuItem.icon !== '') {
              $("#img_" + item.id).attr('src', inThis.getIcon(menuItem.icon + "_sel"));
            } else {
              $("#img_" + item.id).attr('src', '/assets/menu/sales_sel.svg');
            }
          }
        });
      }
    }
  }

  constructID(name) {
    return name.toString().toLowerCase().replace(" ", "_");
  }

  clearActive(menuList) {
    const inThis = this;
    menuList.forEach(item => {
      inThis.renderer.removeClass(item, 'active');
      inThis.renderer.removeClass(document.getElementById('a_' + item.id), 'active');
      if ($("#img_" + item.id).attr('src')) {
        $("#img_" + item.id).attr('src', $("#img_" + item.id).attr('src').replace('_sel', ''));
      }
    });
  }

  getIcon(img) {
    return '/assets/menu/' + img + '.svg';
  }

  ngOnInit() {
    let url;
    let subUrl;
    let urlSplit;
    this.header_title = localStorage.getItem('page_header');
    this.router.events.forEach((event) => {

      if (event instanceof NavigationStart) {
        this.apiService.check_token_expiry();
        url = event.url;
        urlSplit = url.split('?');
        if (this.featureMenuv2) {
          let url1 = urlSplit[0];
          url1 = url.split('/');
          if (url1[1] === '' || url1[1] === 'login' || url1[1] === 'imagerender' || url1[1] === 'meet_attendance') {                  // live
            this.showHead = false;
          } else {
            this.showHead = true;
            this.fetchMenus(urlSplit[0]);
          }
        } else {
          if (url.length > 1) {
            subUrl = urlSplit[1];
          }
          console.log(subUrl);
          url = urlSplit[0];
          url = url.split('/');
          // if (url.length === 2) {                    //local
          if (url.length > 2) {
            subUrl = url[2];
          }
          if (url[1] === '' || url[1] === 'login' || url[1] === 'imagerender' || url[1] === 'meet_attendance') {                  // live
            this.showHead = false;
          } else {
            // url = url[2];                           //local
            url = url[1];                        // live
            if (url === 'eod-dclist') {
              this.showHead = localStorage.getItem('dc_head') === 'true';
              if (this.showHead) {
                this.changeActive('Settlement');
              }
            } else if (url === 'eod-vanlist') {
              this.showHead = localStorage.getItem('dc_head') === 'true';
              if (this.showHead) {
                this.changeActive('Settlement');
              }
            } else if (url === 'eod') {
              this.showHead = localStorage.getItem('dc_head') === 'true';
              if (this.showHead) {
                this.changeActive('Settlement');
              }
            } else if (url === 'sales') {
              this.showHead = localStorage.getItem('sales_head') === 'true';
              if (this.showHead) {
                this.changeActive('Invoices');
              }
            } else if (url === 'mystock') {
              this.showHead = localStorage.getItem('dms_module') === 'true';
              if (this.showHead) {
                this.changeActive('My Stock');
              }
            } else if (url === 'pro_dc_list') {
              this.showHead = localStorage.getItem('pro_head') === 'true';
              if (this.showHead) {
                this.changeActive('Production Order');
              }
            } else if (url === 'prolist') {
              this.showHead = localStorage.getItem('pro_head') === 'true';
              if (this.showHead) {
                this.changeActive('Production Order');
              }
            } else if (url === 'prolistv3') {
              this.showHead = (localStorage.getItem('pro_head') === 'true') && (localStorage.getItem('feature_pro_listV3') === 'true');
              if (this.showHead) {
                this.changeActive('Production Order');
              }
            } else if (url === 'prolistv4') {
              this.showHead = (localStorage.getItem('pro_head') === 'true') && (localStorage.getItem('feature_pro_listV4') === 'true');
              this.header_title = 'Production Order';
              if (this.showHead) {
                this.changeActive('Production Order');
              }
            } else if (url === 'dashboard' || url === 'dash') {
              this.showHead = ((localStorage.getItem('resfreshToken') !== 'null') && (localStorage.getItem('resfreshToken') !== null));
              if (this.showHead) {
                this.changeActive('Dashboard');
              }
            } else if (url === 'rawsalereport') {
              this.showHead = localStorage.getItem('dc_report') === 'true';
            } else if (url === 'settlesalereport') {
              this.showHead = localStorage.getItem('team_report') === 'true';
            } else if (url === 'usercollectionreport') {
              this.showHead = localStorage.getItem('user_report') === 'true';
            } else if (url === 'loadinreport') {
              this.showHead = localStorage.getItem('loadin_report') === 'true';
            } else if (url === 'dailycollection') {
              this.showHead = localStorage.getItem('daily_collection') === 'true';
            } else if (url === 'stockinhand') {
              this.showHead = localStorage.getItem('stockhand_module') === 'true';
            } else if (url === 'einvoice') {
              this.showHead = localStorage.getItem('einvoice_report_module') === 'true';
            } else if (url === 'vanreport') {
              this.showHead = localStorage.getItem('van_report') === 'true';
            } else if (url === 'cancelreport') {
              this.showHead = localStorage.getItem('cancel_report') === 'true';
            } else if (url === 'schemereport') {
              this.showHead = localStorage.getItem('scheme_report') === 'true';
            } else if (url === 'beatreport') {
              this.showHead = localStorage.getItem('beat_report') === 'true';
            } else if (url === 'tireport') {
              this.showHead = localStorage.getItem('ti_report') === 'true';
            } else if (url === 'btlreport') {
              this.showHead = localStorage.getItem('btl_report') === 'true';
            } else if (url === 'outaggreport') {
              this.showHead = localStorage.getItem('outlet_agg_report') === 'true';
            } else if (url === 'outaggprodreport') {
              this.showHead = localStorage.getItem('outlet_prod_report') === 'true';
            } else if (url === 'pricelistreport') {
              this.showHead = localStorage.getItem('pricelist_report') === 'true';
            } else if (url === 'outletdumpreport') {
              this.showHead = localStorage.getItem('outlet_report') === 'true';
            } else if (url === 'livestockreport') {
              this.showHead = localStorage.getItem('livestock_report') === 'true';
              this.changeActive('Stock');
            } else if (url === 'dms') {
              this.header_title = 'DMS Page Title';
              this.showHead = true;
            } else if (url === 'vansales') {
              this.header_title = '{Page Title}';
              this.showHead = true;
            } else if (url === 'reports') {
              this.showHead = true;
              this.changeActive('Reports');
              /*if (subUrl === 'report_availability') {
              } else if (subUrl === 'tl_cluster_level_rollup') {
              } else if (subUrl === 'visibility_report') {
              } else if (subUrl === 'volume_distribution') {
              } else if (subUrl === 'survey_kyc') {
              } else if (subUrl === 'task_activation') {
              } else if (subUrl === 'outlet_aggregate_report') {
              }*/
            } else if (url === 'boards') {
              this.header_title = 'Board One';
              this.showHead = true;
              this.changeActive('Dashboard');
            } else if (url === 'logs') {
              this.showHead = true;
              this.changeActive('Report Logs');
            } else if (url === 'prodcollectionreport') {
              this.showHead = localStorage.getItem('prod_report') === 'true';
              this.changeActive('Reports');
            } else if (url === 'salereturnreport') {
              this.showHead = localStorage.getItem('return_report') === 'true';
              this.changeActive('Reports');
            } else if (url === 'order_aging_report') {
              this.showHead = localStorage.getItem('payment_ageing') === 'true';
              this.changeActive('Reports');
            } else if (url === 'product_ageing_report') {
              this.showHead = true;
              this.changeActive('Reports');
            } else if (url === 'outlet_overdue_report') {
              this.showHead = localStorage.getItem('outlet_overdue') === 'true';
              this.changeActive('Reports');
            } else if (url === 'payment_approval_report') {
              this.showHead = localStorage.getItem('cheque_approval') === 'true';
              this.changeActive('Reports');
            } else if (url === 'growthreport') {
              this.showHead = localStorage.getItem('growth_report') === 'true';
              this.changeActive('Reports');
            } else if (url === 'mtsalesdc') {
              this.showHead = localStorage.getItem('mt_module') === 'true';
              if (this.showHead) {
                this.changeActive('Pre Orders');
              }
            } else if (url === 'mtoutsalesdc') {
              this.showHead = localStorage.getItem('mt_module') === 'true';
              if (this.showHead) {
                this.changeActive('Pre Orders');
              }
            } else if (url === 'po') {
              this.showHead = localStorage.getItem('mt_module') === 'true';
              if (this.showHead) {
                this.changeActive('Purchase Order');
              }
            } else if (url === 'po2') {
              this.showHead = localStorage.getItem('mt_module') === 'true';
              if (this.showHead) {
                this.changeActive('Purchase Order');
              }
            } else if (url === 'cd') {
              this.showHead = true;
              if (this.showHead) {
                this.changeActive('Common Dashboard');
              }
            } else if (url === 'po_po') {
              this.showHead = localStorage.getItem('po_module') === 'true';
              if (this.showHead) {
                this.changeActive('PO');
              }
            } else if (url === 'new_po') {
              this.showHead = localStorage.getItem('po_module') === 'true';
            } else if (url === 'receipts') {
              this.showHead = localStorage.getItem('po_module') === 'true';
              if (this.showHead) {
                this.changeActive('Receipts');
              }
            } else if (url === 'returns') {
              this.showHead = localStorage.getItem('po_module') === 'true';
              if (this.showHead) {
                this.changeActive('Returns');
              }
            } else if (url === 'new_invoice' || url === 'new_order') {
              this.showHead = localStorage.getItem('dms_module') === 'true';
            } else if (url === 'orders') {
              this.showHead = localStorage.getItem('dms_module') === 'true';
              if (subUrl === 'type=S') {
                this.header_title = 'Sale Orders';
                if (this.showHead) {
                  this.changeActive('dms_orders');
                }
              } else {
                this.header_title = 'Purchase Orders';
                if (this.showHead) {
                  this.changeActive('dms_orders_p');
                }
              }
            } else if (url === 'dms_payments') {
              this.showHead = localStorage.getItem('dms_module') === 'true';
            } else if (url === 'order_invoices') {
              const spl = subUrl.split('&');
              subUrl = spl[0];
              this.showHead = localStorage.getItem('dms_module') === 'true';
              if (subUrl === 'type=S') {
                if (spl.length > 1 && spl[1] === 'order_type=return') {
                  this.header_title = 'Sale Returns';
                  if (this.showHead) {
                    this.changeActive('dms_s_return');
                  }
                } else {
                  this.header_title = 'Sale Invoices';
                  if (this.showHead) {
                    this.changeActive('dms_invoice');
                  }
                }
              } else if (subUrl === 'type=P') {
                if (spl.length > 1) {
                  this.header_title = 'Purchase Returns';
                  if (this.showHead) {
                    this.changeActive('dms_p_return');
                  }
                } else {
                  this.header_title = 'Purchase Invoices';
                  if (this.showHead) {
                    this.changeActive('dms_invoice_p');
                  }
                }
              }
            } else if (url === 'retailer_list') {
              this.showHead = localStorage.getItem('module_distributors') === 'true';
              if (this.showHead) {
                this.changeActive('Distributors');
              }
            } else if (url === 'return_invoice_list' || url === 'return_invoice') {
              this.showHead = localStorage.getItem('dms_module') === 'true';
              this.header_title = 'Return Invoice';
              if (this.showHead) {
                this.changeActive('Return Invoice');
              }
            } else if (url === 'stock_log_entry') {
              this.showHead = true;
              if (this.showHead) {
                this.changeActive('Reports');
              }
            } else if (url === 'cost_log_entry') {
              this.showHead = true;
              if (this.showHead) {
                this.changeActive('Reports');
              }
            } else if (url === 'expense') {
              this.showHead = true;
              if (subUrl === 'my_expense') {
                this.changeActive('My Expenses');
              } else if (subUrl === 'settle_expense') {
                this.changeActive('Settle');
              } else {
                this.changeActive('Approve');
              }
            } else if (url === 'task') {
              this.showHead = true;
              if (this.showHead) {
                this.changeActive('Task');
              }
              if (subUrl === 'survey') {
                if (this.showHead) {
                  this.changeActive('Survey');
                }
              }
            } else if (url === 'task_planner') {
              this.showHead = true;
              this.changeActive('Task Planner');
            } else if (url === 'user_tasks') {
              this.showHead = true;
              if (this.showHead) {
                this.changeActive('User Tasks');
              }
            } else if (url === 'retail_audit') {
              this.showHead = true;
              if (this.showHead) {
                this.changeActive('Retail Audit');
              }
              /* if(subUrl=='availability'){
                 this.header_title = 'Retail Audit Availability';
                 if (this.showHead) { this.changeActive('Availability'); }
               }
               if(subUrl=='visibility'){
                 this.header_title = 'Retail Audit Visibility';
                 if (this.showHead) { this.changeActive('Visibility'); }
               }
               if(subUrl=='marketshare'){
                 this.header_title = 'Retail Audit Market Share';
                 if (this.showHead) { this.changeActive('Market Share'); }
               }
               if(subUrl=='stocks'){
                 this.header_title = 'Retail Audit Stocks';
                 if (this.showHead) { this.changeActive('Stocks'); }
               }*/
            } else if (url === 'influencers') {
              this.showHead = true;
              if (subUrl === 'manage') {
                this.header_title = 'Influencers';
                if (this.showHead) {
                  this.changeActive('Manage');
                }
              }
            } else if (url === 'customers') {
              this.showHead = true;
              if (subUrl === 'request') {
                if (this.showHead) {
                  this.changeActive('Change Request');
                }
              }
              if (subUrl === 'retailers') {
                if (this.showHead) {
                  this.changeActive('Retailers');
                }
              }
              if (subUrl === 'influencers') {
                if (this.showHead) {
                  this.changeActive('Influencers');
                }
              }
              if (subUrl === 'projects') {
                if (this.showHead) {
                  this.changeActive('Projects');
                }
              }
              if (subUrl === 'leads') {
                if (this.showHead) {
                  this.changeActive('Leads');
                }
              }
              if (subUrl === 'partner_leads') {
                if (this.showHead) {
                  this.changeActive('Partner Leads');
                }
              }
              if (subUrl === 'new_outlet') {
                if (this.showHead) {
                  this.changeActive('New Outlets');
                }
              }
              if (subUrl === 'outlet_product_recommendation') {
                if (this.showHead) {
                  this.changeActive('Recommendations');
                }
              }
              if (subUrl === 'partner_approve') {
                if (this.showHead) {
                  this.changeActive('Approve Partner');
                }
              }
              if (subUrl === 'retailer_approve') {
                if (this.showHead) {
                  this.changeActive('Approve Retailer');
                }
              }
              if (subUrl === 'planogram') {
                if (this.showHead) {
                  this.changeActive('Planogram');
                }
              }
            } else if (url === 'assets') {
              this.showHead = true;
              if (subUrl === 'request') {
                if (this.showHead) {
                  this.changeActive('Request');
                }
              }
              if (subUrl === 'tagged') {
                if (this.showHead) {
                  this.changeActive('Deployed');
                }
              }
              if (subUrl === 'retail_request') {
                if (this.showHead) {
                  this.changeActive('Retail Asset');
                }
              }
              if (subUrl === 'service_ticket') {
                if (this.showHead) {
                  this.changeActive('Asset Service');
                }
              }
            } else if (url === 'users') {
              this.showHead = true;
              if (subUrl === 'team') {
                if (this.showHead) {
                  this.changeActive('Team');
                }
              }
              if (subUrl === 'assessment') {
                if (this.showHead) {
                  this.changeActive('Assessment');
                }
              }
              if (subUrl === 'kpiscorecard') {
                if (this.showHead) {
                  this.changeActive('KPI Score Card');
                }
              }
              if (subUrl !== 'team' && subUrl !== 'assessment' && subUrl !== 'kpiscorecard') {
                if (this.showHead) {
                  this.changeActive('Users');
                }
              }
            } else if (url === 'retail_survey') {
              this.showHead = true;
              if (subUrl === 'kyc') {
                if (this.showHead) {
                  this.changeActive('Outlet KYC');
                }
              }
            } else if (url === 'market_intelligence_report') {
              this.showHead = true;
              // this.showHead = localStorage.getItem('market_intelligence_report') === 'true';
            } else if (url === 'market_intelligence_summary_report') {
              this.showHead = true;
              // this.showHead = localStorage.getItem('market_intelligence_report') === 'true';
            } else if (url === 'retail_audit_report') {
              this.showHead = true;
              // this.showHead = localStorage.getItem('market_intelligence_report') === 'true';
            } else if (url === 'retail_audit_distribution_report') {
              this.showHead = true;
              // this.showHead = localStorage.getItem('market_intelligence_report') === 'true';
            } else if (url === 'user_survey') {
              this.showHead = true;
              if (this.showHead) {
                this.changeActive('User Survey');
              }
            } else if (url === 'beats') {
              this.showHead = true;
              if (subUrl === 'beat_plan') {
                this.header_title = 'Beat Plan';
                if (this.showHead) {
                  this.changeActive('Beat Plan');
                }
              }
              if (subUrl === 'beat_schedule') {
                if (this.showHead) {
                  this.changeActive('Beat Schedule');
                }
              }
              if (subUrl === 'beat_retailer') {
                if (this.showHead) {
                  this.changeActive('Beat Retailer');
                }
              }
              if (subUrl === 'territory') {
                if (this.showHead) {
                  this.changeActive('Territory');
                }
              }
            } else if (url === 'products') {
              this.showHead = true;
              if (!subUrl) {
                if (this.showHead) {
                  this.changeActive('Products');
                }
              }
              if (subUrl === 'schemes') {
                if (this.showHead) {
                  this.changeActive('Schemes');
                }
              }
              if (subUrl === 'pricelist') {
                if (this.showHead) {
                  this.changeActive('Pricelist');
                }
              }
              if (subUrl === 'stock') {
                if (this.showHead) {
                  this.changeActive('Stock');
                }
              }
            } else if (url === 'distributor_audit') {
              this.showHead = true;
              if (this.showHead) {
                this.changeActive('Distributor Audit');
              }
              // } else if (url === 'delivery_plan') {
              //   this.header_title = 'Delivery Plan';
              //   this.showHead = true;
              //   if (this.showHead) {
              //     this.changeActive('Delivery Plan');
              //   }
            } else if (url === 'survey_detail') {
              this.showHead = true;
            } else if (url === 'claims') {
              this.showHead = true;
              if (subUrl === 'list') {
                if (this.showHead) {
                  this.changeActive('View');
                }
              }
              if (subUrl === 'raise') {
                this.header_title = 'Raise Claims';
                if (this.showHead) {
                  this.changeActive('Raise');
                }
              }
              if (subUrl === 'returns') {
                if (this.showHead) {
                  this.changeActive('Returns');
                }
              }
              if (subUrl === 'approve') {
                if (this.showHead) {
                  this.changeActive('Approve');
                }
              }
            } else if (url === 'vansale_credit_notes') {
              this.showHead = localStorage.getItem('feature_credit_notes') === 'true';
              if (this.showHead) {
                this.changeActive('Credit Notes');
              }
            } else if (url === 'service') {
              this.showHead = true;
              if (subUrl === 'request') {
                if (this.showHead) {
                  this.changeActive('Service Request');
                }
              }
            } else if (url === 'gstr') {
              this.showHead = true;
              if (subUrl === '1') {
                if (this.showHead) {
                  this.changeActive('GSTR1');
                }
              }
              if (subUrl === '2') {
                if (this.showHead) {
                  this.changeActive('GSTR2');
                }
              }
              if (subUrl === 'tcs') {
                if (this.showHead) {
                  this.changeActive('TCS');
                }
              }
            } else if (url === 'daily_user_tracking') {
              this.showHead = localStorage.getItem('feature_tracking') === 'true';
              if (this.showHead) {
                this.changeActive('Daily User');
              }
            } else if (url === 'monthly_user_tracking') {
              this.showHead = localStorage.getItem('feature_tracking') === 'true';
              if (this.showHead) {
                this.changeActive('Monthly User');
              }
            } else if (url === 'daywise_tracking') {
              this.showHead = localStorage.getItem('feature_tracking') === 'true';
              if (this.showHead) {
                this.changeActive('Daywise');
              }
            }
            if (this.header_title !== null) {
              this.sharedService.setHeader(this.header_title);
            }
          }

          localStorage.setItem('page_header', this.header_title);
        }
      }
    });
  }

  getTemplates() {
    this.selectedTemplate = null;
    this.order_templ_config = null;
    this.orderTemplate = false;
    this.invoice_templ_config = null;
    this.invoiceTemplate = false;
    if (localStorage.getItem('custom_templates')) {
      const custom_templates = JSON.parse(localStorage.getItem('custom_templates'));
      const orderTemplates = custom_templates.filter(elem => {
        return elem.type === 'Secondary Print PDF';
      });
      if (orderTemplates.length > 0) {
        this.order_templ_config = [{name: 'Default'}].concat(orderTemplates);
        this.orderTemplate = true;
      }
      const invoiceTemplates = custom_templates.filter(elem => {
        return elem.type === 'Invoice PDF';
      });
      if (invoiceTemplates.length > 0) {
        this.invoice_templ_config = [{name: 'Default'}].concat(invoiceTemplates);
        this.invoiceTemplate = true;
      }

      const appConfig = JSON.parse(localStorage.getItem('app')).config_json;
      if (appConfig && appConfig.hasOwnProperty('default_gst_print')) {
        const gst_template = orderTemplates.find(x => x.id === appConfig.default_gst_print);
        if (gst_template) {
          this.gst_template = gst_template;
        } else {
          this.gst_template = null;
        }
      } else {
        this.gst_template = null;
      }

      if (appConfig && appConfig.hasOwnProperty('default_non_gst_print')) {
        const non_gst_template = orderTemplates.find(x => x.id === appConfig.default_non_gst_print);
        if (non_gst_template) {
          this.non_gst_template = non_gst_template;
        } else {
          this.non_gst_template = null;
        }
      } else {
        this.non_gst_template = null;
      }
    }
  }

  checkAndAssignTemplate(order) {
    if (order.hasOwnProperty('partner_gstn')) {
      if (order.partner_gstn) {
        if (this.gst_template) {
          order['selectedTemplate'] = this.gst_template;
        } else {
          order['selectedTemplate'] = {name: 'Default'};
        }
      } else {
        if (this.non_gst_template) {
          order['selectedTemplate'] = this.non_gst_template;
        } else {
          order['selectedTemplate'] = {name: 'Default'};
        }
      }
    } else {
      order['selectedTemplate'] = {name: 'Default'};
    }
  }

  printAll(rows, field, url, zipdownload) {
    if (!rows || rows.length === 0) {
      this.toastr.error("No Rows Selected");
    } else {
      const order_ids = [];
      rows.forEach(row => {
        order_ids.push(row[field]);
      });
      this.getPrintDetails(order_ids, url, zipdownload);
    }
  }

  printEway(id, vansale_ids, type) {
    this.printType = 'Eway';
    this.userData.access_token = localStorage.getItem('resfreshToken');
    const order_data = {
      access_token: this.userData.access_token,
      id,
      vansale_ids,
      type
    };
    this.apiService.post('/api/eway_bills/getEwayForVansales', order_data)
      .subscribe(async res => {
        console.log(res);
        if (res.hasOwnProperty('result') && (res['result'].status === 200) && (res['result'].data.length > 0)) {
          const orderdata = res.result.data;
          await this.generateEwayPrint(orderdata);
        }
      });
  }

  getPrintDetails(order_ids, url, zipdownload) {
    this.getTemplates();
    this.userData.access_token = localStorage.getItem('resfreshToken');
    const order_data = {
      access_token: this.userData.access_token,
      order_id: order_ids
    };
    if (url === '/api/pwa_dms_connects/get_invoice_print_details') {
      this.printType = 'Invoice';
      this.templ_config = this.invoice_templ_config;
      this.template = this.invoiceTemplate;
    }  else {
      this.printType = 'Order';
      this.templ_config = this.order_templ_config;
      this.template = this.orderTemplate;
    }
    this.apiService.post(url, order_data)
      .subscribe(async res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          const orderdata = res.results.data;
          await this.processPrintDetails(orderdata, zipdownload);
        }
      });
  }

  async generateEwayPrint(orderdata) {
    const all_orders = [];
    for (const data of orderdata) {
      if (data?.print_dtls) {
        all_orders.push(data.print_dtls);
      }
    }
    this.print_details = all_orders;
    $('#DefaultTemplateModal').modal('show');
  }

  async processPrintDetails(orderdata, zipDownload) {
    const inThis = this;
    let total_qty = 0;
    let total_discount = 0;
    let printDetails = {};
    const all_orders = [];
    this.total = orderdata.length;
    for (const data of orderdata) {
      if (zipDownload) {
        this.completed = 0;
        $('#ZipProgressModal').modal('show');
      }
      total_qty = 0;
      printDetails = data;
      this.checkAndAssignTemplate(printDetails);
      const tax_breaks = [];
      const taxnotes = [];
      printDetails['label_config'] = {};
      const labels = JSON.parse(localStorage.getItem('custom_labels'));
      if (labels) {
        labels.forEach(lab => {
          printDetails['label_config'][lab.label] = lab.display;
        });
      } else {
        printDetails['label_config']['PrimaryCurrency'] = '';
        printDetails['label_config']['SecondaryCurrency'] = '';
        printDetails['label_config']['SupplierLabel'] = 'Supplier';
        printDetails['label_config']['TaxLabel'] = 'GST';
      }
      if ((!printDetails['dc_name'] || printDetails['dc_name'] === '') && printDetails['supplier']) {
        printDetails['dc_name'] = printDetails['supplier'];
        printDetails['dc_code'] = printDetails['supp_code'];
        printDetails['dc_email'] = printDetails['supplier_email'];
        printDetails['dc_mobile'] = printDetails['supplier_mobile'];
        printDetails['dc_street1'] = printDetails['supplier_street1'];
        printDetails['dc_street2'] = printDetails['supplier_street2'];
      }
      if (!printDetails['grn']) {
        printDetails['grn'] = '';
      }
      if (typeof printDetails['tax_notes'] === 'string') {
        printDetails['tax_notes'] = JSON.parse(printDetails['tax_notes']);
      }
      if (printDetails['tax_notes']) {
        Object.keys(printDetails['tax_notes']).forEach(each_tax => {
          if (each_tax && printDetails['tax_notes'][each_tax] && typeof printDetails['tax_notes'][each_tax] !== 'object') {
            tax_breaks.push({
              name: each_tax,
              tax: printDetails['tax_notes'][each_tax].toFixed(inThis.apiService.decimalPolicy)
            });
            taxnotes.push(each_tax);
          }
        });
      }
      printDetails['tax_breakup'] = tax_breaks;
      if (printDetails['partner_id'] === printDetails['partner_shipping_id']) {
        printDetails['customer_skip'] = true;
      } else {
        printDetails['customer_skip'] = false;
      }
      printDetails['print_text'] = inThis.domSanitizer.bypassSecurityTrustHtml(printDetails['print_text']);
      let rate = 0;
      let spli;
      printDetails['sale_items'].forEach(sale_item => {
        sale_item['label'] = '[' + sale_item.type + ']';
        const breaks = [];
        sale_item['new_tax'] = 0;
        if (sale_item['tax_notes']) {
          sale_item['tax_notes'] = JSON.parse(sale_item['tax_notes']);
          if (typeof sale_item['tax_notes'] === 'string' && sale_item['tax_notes'] !== "") {
            sale_item['tax_notes'] = JSON.parse(sale_item['tax_notes']);
          }
        }
        if (sale_item['tax_notes'] && sale_item['tax_notes'].constructor === Object) {
          Object.keys(sale_item['tax_notes']).forEach(each_tax => {
            if (sale_item['tax_notes'][each_tax].includes('(')) {
              spli = sale_item['tax_notes'][each_tax].split('(');
              rate = spli[1].split('%')[0].split(')')[0];
              breaks.push({name: each_tax, tax: spli[0], rate});
            } else {
              if (sale_item.hasOwnProperty('tax')) {
                spli = sale_item['tax'].split('(');
                rate = spli[1].split('%')[0].split(')')[0];
              } else {
              }
              breaks.push({name: each_tax, tax: sale_item['tax_notes'][each_tax], rate});
            }

          });
        }
        sale_item['tax_notes'] = breaks;

        total_discount += parseFloat(sale_item.vps_dis);
        if (printDetails['d_status'] === 'delivered') {
          sale_item['abs_qty'] = Math.abs(sale_item['delivered_qty']);
          total_qty += parseInt(sale_item.delivered_qty, 0);
          sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.delivered_qty)) -
            (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
        } else {
          sale_item['abs_qty'] = Math.abs(sale_item['qty']);
          total_qty += parseInt(sale_item.qty, 0);
          sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.qty)) -
            (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
        }
        sale_item.unit_price = sale_item.unit_price.toFixed(inThis.apiService.decimalPolicy);
        sale_item['reporting_uom'] = "";
        if (sale_item.report_uom && sale_item.report_uom.length > 0) {
          let reportUoms = sale_item.report_uom;
          reportUoms = reportUoms.sort((a, b) => (a.factor_inv > b.factor_inv ? -1 : 1));
          let qty = sale_item['abs_qty'];
          for (let i = 0; i < reportUoms.length; i++) {
            const chunks = qty / reportUoms[i].factor_inv;
            if (chunks >= 1) {
              sale_item['reporting_uom'] += parseInt(String(chunks), 10) + ' ' + reportUoms[i].name + '(s), ';
              qty = qty % reportUoms[i].factor_inv;
              if (qty === 0) {
                break;
              }
            } else {
            }
          }
          if (sale_item['reporting_uom'] && sale_item['reporting_uom'] !== "") {
            sale_item['reporting_uom'] = sale_item['reporting_uom'].substring(0, sale_item['reporting_uom'].length - 2);
          }
        }

      });
      printDetails['total_qty'] = total_qty;
      printDetails['discount'] = total_discount.toFixed(inThis.apiService.decimalPolicy);
      if (printDetails['date_delivery']) {
        printDetails['invoice_date'] = inThis.datePipe.transform((new Date(printDetails['date_delivery'] + ' UTC')).toString(), 'dd/MM/yyyy');
      } else if (printDetails['date_delivered'] != null) {
        printDetails['invoice_date'] = inThis.datePipe.transform((new Date(printDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      }
      printDetails['order_date'] = inThis.datePipe.transform((new Date(printDetails['order_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      if (printDetails['date_delivered'] != null) {
        printDetails['date_delivered'] = inThis.datePipe.transform((new Date(printDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      }
      if (printDetails['logo']) {
        const resp = await inThis.apiService.fetchImageWait({
          access_token: inThis.userData.access_token,
          data: [{store_fname: printDetails['logo'], container: "res_partner"}]
        });
        console.log(resp);
        if (resp['result'].length > 0) {
          printDetails['logo'] = inThis.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + resp['result'][0].bill_datas);
        }
        all_orders.push(printDetails);
      } else {
        all_orders.push(printDetails);
      }
      this.print_details = all_orders;
      console.log(all_orders);
    }
    if (this.printType === 'Order' && (this.gst_template || this.non_gst_template)) {
      if (zipDownload) {
        this.generateBlob(this, this.print_details);
      } else {
        this.checkTemplateandDisplay();
      }
    } else {
      this.checkTemplateandDisplay();
    }
  }

  checkTemplateandDisplay() {
    if (this.gst_template || this.non_gst_template) {
      $('#DefaultTemplateModal').modal('show');
    } else {
      if (this.template) {
        const el: HTMLElement = this.customPrintClick.nativeElement;
        el.click();
      } else {
        const el: HTMLElement = this.interPrint.nativeElement;
        el.click();
      }
    }
  }

  closeZipDialog() {
    $('ZipProgressModal').modal('hide');
  }

  async generateBlob(inthis, all_orders) {
    const zip = new JSZip();
    inthis.zip_order = null;
    inthis.canvas = null;
    const count = all_orders.length;
    this.completed = 0;
    this.total = all_orders.length;
    let i = 0;
    this.progress = 20;
    const initial = this.progress;
    const diff = 100 - this.progress;
    for (const key in all_orders) {
      i++;
      inthis.zip_order = null;
      inthis.canvas = null;
      if (all_orders[key].hasOwnProperty('gstn')) {
        if (all_orders[key].gstn) {
          if (this.gst_template) {
            this.selectedTemplate = this.gst_template;
          } else {
            this.selectedTemplate = {name: 'Default'};
          }
        } else {
          if (this.non_gst_template) {
            this.selectedTemplate = this.non_gst_template;
          } else {
            this.selectedTemplate = {name: 'Default'};
          }
        }
      } else {
        this.selectedTemplate = {name: 'Default'};
      }
      await this.timeout(10);
      inthis.zip_order = all_orders[key];
      await this.timeout(10);
      const element = document.getElementById('print-section-sales-zip');
      const htmlresp1 = await html2canfast(document.querySelector("#print-section-sales-zip"), {
        renderName: 'invoice',
        replaceSelector: '#print-section-sales-zip',
        removeContainer: false,
        allowTaint: true,
        useCORS: true,
        backgroundColor: '#ffffff'
      }).then(canvas => {
        inthis.canvas = canvas.toDataURL();
      });
      const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      const position = 0;
      const imgProps = pdf.getImageProperties(inthis.canvas);
      const pdfWidth = pdf.internal.pageSize.getWidth() - 40;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(inthis.canvas, 'JPEG', 20, 20, pdfWidth, pdfHeight, '', 'FAST');
      await this.addFiletoZip(zip, all_orders[key]['invoice_no'] + `.pdf`, pdf.output('blob'));
      this.progress = Math.round(initial + ((i / count) * diff));
      this.completed += 1;
      if (i === count) {
        zip.generateAsync({type: 'blob'}).then(zipFile => {
          const currentDate = new Date().getTime();
          const fileName = `invoice-${currentDate}.zip`;
          return saveAs(zipFile, fileName);
        });
      }
    }
    $('ZipProgressModal').modal('hide');
  }

  addFiletoZip(zip, name, pdf) {
    return new Promise(async (resolve, reject) => {
      try {
        zip.file(name, pdf);
        resolve('Dont do part2 until the.thecanvas is populated');
      } catch (error) {
        reject(error);
      }
    });
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
