import {Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../../_services/api.service";
import {ToastrService} from "ngx-toastr";
import {LocalforageService} from "../../../_services/localforage.service";
import {GridOptions, RowNode} from "@ag-grid-enterprise/all-modules";
import {ErrorObject} from "../../../error/error-model";
import {ErrorHandler} from "../../../error/error-handler";
import {DatePipe} from "@angular/common";
import {DMSBasePage} from "../../../pagebase/components/DMSBasePage";
import {SharedService} from "../../../_services/shared_service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatTabChangeEvent} from "@angular/material/tabs";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import {QtyEditRendererComponent} from "../../../qtyEditRenderer";
import {ErrortooltipComponent} from "../../../errortooltip";
import {DomSanitizer} from "@angular/platform-browser";
import {SchemeCalculator} from "../../../pagebase/components/SchemeCalculator";
import {TaxCalculator} from "../../../pagebase/components/TaxCalculator";
import {AppComponent} from "../../../app.component";

@Component({
  selector: 'app-bulk-invoice',
  templateUrl: './van-devlivery_plan_popup.html',
  styleUrls: ['../../../admin/upload/common/baseUpload.scss']

})

export class VanDeliveryPlanComponent extends DMSBasePage implements OnInit {
  @Output() transferCompleteEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('interPrint') public interPrint: ElementRef;
  OPERATION_MODE = 'S';

  order_ids = [];
  product_ids: [];
  SUPPLIER_ID = 0;
  // SUPPLIER_TERRITORY_id = 0;
  SUPPLIER_OBJ = {};

  errorDataCount = 0;
  totalDataCount = 0;
  validDataCount = 0;

  dataLoaded: any;
  gridOptions: GridOptions;
  styleGrid: any;
  columnDefs = [];
  frameworkComponents: any = {};
  context: any;
  rowData = [];
  public getRowId;
  public gridColumnApi: any;
  public gridApi: any;

  public USER: any = {};
  public userContext = {
    access_token: null,
    data: null
  };
  activetab = 'Orders';


  grid2Context: any;
  grid2columnDefs = [];
  grid2Options: GridOptions;
  grid2Api: any;
  grid2ColumnApi: any;
  grid2RowData = [];
  grid2Style: any;

  enable_confirm_orders_button = false;
  enable_create_invoice_button = false;
  deliveryPlanData: any = {};
  dp_mode = "create";
  dpList = [];
  validInvoices = 0;
  invoicecount = 0;
  quantity = 0;
  weight = 0.00;
  volume = 0.00;
  grandtotal = 0.0;
  invoice_ids = [];
  apiData: any = [];
  quantityEdited = false;
  editedOrders: any = [];
  productStock: any = [];
  menuWidth;
  deliveryPlanUpdated = false;
  noOrdersToTransfer = false;
  public tooltipShowDelay = 0;
  public tooltipHideDelay = 2400000;
  dpEdit = false;
  all_orders = [];
  printType = 'Invoice';
  usersList = [];

  constructor(public dialog: MatDialog,
              public apiService: ApiService,
              public sharedService: SharedService,
              public toastr: ToastrService,
              public elRef: ElementRef,
              public datePipe: DatePipe,
              protected route: ActivatedRoute,
              protected _localCacheService: LocalforageService,
              public router: Router,
              public domSanitizer: DomSanitizer,
              public appComponent: AppComponent,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    super(apiService, sharedService, elRef, datePipe, _localCacheService, toastr, route, dialog, router, domSanitizer, appComponent);

    this.userContext.access_token = localStorage.getItem('resfreshToken');
    this.USER = JSON.parse(localStorage.getItem('user'));
    this.editMode = true;
    this.order_ids = dialogdata.ids;

    this.SUPPLIER_ID = dialogdata.supplier_id;
    this.deliveryPlanData.supplier_id = dialogdata.supplier_id;
    // this.SUPPLIER_TERRITORY_id = dialogdata.supplier_territory_id;

    this.userContext.data = {};
    this.userContext.data['order_ids'] = dialogdata.ids;

    this.gridOptions = {
      context: this,
      rowHeight: 40,
      rowSelection: 'multiple',
      groupSelectsChildren: false,
      enableRangeSelection: false,
      enableCharts: false,
      animateRows: false,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheaderwhite',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapText: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
    } as GridOptions;

    this.grid2Options = {
      context: this,
      rowHeight: 40,
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheaderwhite',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      getRowHeight: params => {
        if (params.node && params.node.detail) {
          const offset = 40;
          const allDetailRowHeight =
            params.data.orders.length *
            params.api.getSizesForCurrentTheme().rowHeight;
          const gridSizes = params.api.getSizesForCurrentTheme();
          return (
            allDetailRowHeight +
            ((gridSizes && gridSizes.headerHeight) || 0) +
            offset
          );
        }
      },
      detailCellRendererParams: this.getDetailRendererParams(),
      /*  autoGroupColumnDef : {
          headerName: "Product"
        }*/
    } as GridOptions;

  }

  getDetailRendererParams() {
    return (params) => {
      const res: any = {};
      res.detailGridOptions = {
        suppressRowClickSelection: true,
        enableRangeSelection: false,
        pagination: false,
        rowHeight: 40,
        columnDefs: [
          {headerName: 'Line ID', field: 'line_id', hide: true},
          {headerName: 'Type', field: 'type', hide: true},
          {headerName: 'Order', field: 'name'},
          {headerName: 'Customer', field: 'customer_name'},
          {headerName: 'Quantity', field: 'quantity', cellRenderer: QtyEditRendererComponent},
          {headerName: 'UOM', field: 'uom_name'},
          {headerName: 'Scheme Discount', field: 'scheme_discount', valueFormatter: params1 => this.roundNumber(params1.data.scheme_discount)},
          {
            headerName: 'Sub Total', field: 'price_subtotal', flex: 1,
            valueFormatter: params1 => this.roundNumber(params1.data.price_subtotal)
          },
          {headerName: 'Unit Price', field: 'price_unit', valueFormatter: params1 => this.roundNumber(params1.data.price_unit)},
        ],
        defaultColDef: {
          headerClass: 'myagheaderblue',
          filter: true,
          sortable: true,
          resizable: true,
          enableRowGroup: true,
          flex: 1,
        },
        context: {
          params
        },
        onGridReady: (params1) => {
          // using auto height to fit the height of the detail grid
          params1.api.setDomLayout('autoHeight');
        }
      };
      res.getDetailRowData = (params1) => {
        params1.successCallback(params1.data.orders);
      };

      return res;
    };
    /* return {
       detailGridOptions: {
         suppressRowClickSelection: true,
         enableRangeSelection: false,
         pagination: false,
         columnDefs: [
           {headerName: 'Line ID', field: 'line_id', hide: true},
           {headerName: 'Order', field: 'name'},
           {headerName: 'Customer', field: 'customer_name'},
           {headerName: 'Quantity', field: 'quantity', editable: true},
           {headerName: 'UOM', field: 'product_uom_name'},
           {headerName: 'Scheme Discount', field: 'scheme_discount'},
         ],
         defaultColDef: {
           flex: 1,
         },
         onGridReady: (params) => {
           // using auto height to fit the height of the detail grid
           params.api.setDomLayout('autoHeight');
         },
         onCellEditingStopped: (event) => {
           this.onCellEditingStopped(event);
         }
       },
       getDetailRowData: (params) => {
         params.successCallback(params.data.orders);
       }
     };*/
  }

  ngOnInit(): void {
    this.pageReady = false;
    // this.usersList = [{
    //   id: JSON.parse(localStorage.getItem('user')).id,
    //   user_name: JSON.parse(localStorage.getItem('user')).user_name
    // }];
    const date = new Date();
    this.deliveryPlanData.date = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.gridOptions.getRowStyle = this.getRowStyleFunc;
    this.frameworkComponents = {
      errortooltipComponent: ErrortooltipComponent
    };

    this.configureGrid();
    this.fetchData();
  }

  configureGrid() {
    const ValidationCellClassRules = {
      "cell-pass": params => params.value === 'INVOICED',
      "cell-fail": params => params.value === 'ERROR',
      "cell-invalid": params => params.value === 'INVALID',
      "cell-progress": params => params.value === 'IN PROGRESS'
    };

    const OrderStatusCellClassRules = {
      "cell-progress": params => params.value === 'IN PROGRESS',
      "cell-fail": params => params.value === 'FAILED',
      "cell-pass": params => params.value === 'INVOICED'
    };
    this.columnDefs = [
      {headerName: 'ID', field: 'id', width: 35, hide: true},
      {headerName: 'DATE', field: 'date_order', width: 35},
      {headerName: 'ORDER NO', field: 'name', width: 100},
      {headerName: 'CODE', field: 'customer_code', width: 40},
      {headerName: 'CUSTOMER', field: 'customer_name', width: 70},
      {headerName: 'AREA', field: 'area', width: 50, hide: true},
      {headerName: 'BEAT', field: 'beat', width: 50},
      {headerName: 'STATE', field: 'res_state', width: 50, hide: true},
      {headerName: 'ITEM COUNT', field: 'item_count', width: 30},
      {headerName: 'DISCOUNT', field: 'scheme_discount', width: 30, valueFormatter: params => this.formatNumber(params.data.scheme_discount), cellRenderer: 'agAnimateShowChangeCellRenderer'},
      {headerName: 'SUB TOTAL', field: 'amount_untaxed', width: 30, valueFormatter: params => this.formatNumber(params.data.amount_untaxed), cellRenderer: 'agAnimateShowChangeCellRenderer'},
      {headerName: 'TAX', field: 'amount_tax', width: 30, valueFormatter: params => this.formatNumber(params.data.amount_tax), cellRenderer: 'agAnimateShowChangeCellRenderer'},
      {headerName: 'TOTAL', field: 'amount_total', width: 30, valueFormatter: params => this.formatNumber(params.data.amount_total), cellRenderer: 'agAnimateShowChangeCellRenderer'},
      {
        headerName: 'ORDER STATUS', field: 'state', width: 60, cellClassRules: OrderStatusCellClassRules,
        valueGetter: params => {
          if (params.data && params.data.hasOwnProperty('state')) {
            if (params.data.state === 'sale') {
              return 'Confirmed';
            } else if (params.data.state === 'draft') {
              return 'Draft';
            } else if (params.data.state === 'cancel') {
              return 'Cancel';
            } else {
              return params.data.state;
            }
          }
        },
        cellRenderer(params) {
          let displayElem = '';
          if (params.value === 'Confirmed') {
            displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
          } else if (params.value === 'Cancel') {
            displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
          } else if (params.value === 'Draft') {
            displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #F6A82C;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
          }

          return displayElem;
        }
      },
      {
        headerName: 'STATUS', field: 'status', width: 60, tooltipComponent: 'errortooltipComponent', tooltipField: 'status',
        cellStyle(params) {
          if (params.value) {
            if (params.value === 'INVALID' || params.value === 'FAIL') {
              return {color: '#E24260'};
            } else if (params.value === 'VALID' || params.value === 'INVOICED') {
              return {color: '#379862'};
            } else if (params.value === 'PROCESSING') {
              return {color: '#F6A82C'};
            }
          }
        },
        cellRenderer(params) {
          let displayElem = '';
          if (params.value === 'INVALID' || params.value === 'FAIL') {
            displayElem = '<span class="ml-1 status" style="background-color:#FBE3E7; border:#FBE3E7">' + params.value + '</span>';
          } else if (params.value === 'VALID' || params.value === 'INVOICED') {
            displayElem = '<span class="ml-1 status" style="background-color:#E1F0E7; border:#E1F0E7">' + params.value + '</span>';
          } else if (params.value === 'PROCESSING') {
            displayElem = '<span class="ml-1 status" style="background-color:#FEF2DF; border:#FEF2DF">' + params.value + '</span>';
          }
          return displayElem;
        }
      },
      {headerName: 'INVOICE NO', field: 'invoice_no', width: 50}
    ];

    this.grid2columnDefs = [
      {headerName: 'ID', field: 'id', hide: true},
      {headerName: 'Product', field: 'product_name', flex: 2, cellRenderer: 'agGroupCellRenderer'},
      {headerName: 'Code', field: 'product_code', flex: 1},
      {
        headerName: 'Quantity', field: 'quantity', flex: 1, cellRenderer: 'agAnimateShowChangeCellRenderer',
        cellStyle: params => {
          if (params.data) {
            if (params.value > params.data.stock) {
              return {backgroundColor: '#F8D1C8'};
            }
          }
        }
      },
      {
        headerName: 'Stock', field: 'stock', flex: 1, cellRenderer: 'agAnimateShowChangeCellRenderer'
      },
      {headerName: 'Scheme Discount', field: 'scheme_discount', flex: 1, cellRenderer: 'agAnimateShowChangeCellRenderer', valueFormatter: params => this.roundNumber(params.data.scheme_discount)},
      {headerName: 'Sub Total', field: 'price_subtotal', flex: 1, cellRenderer: 'agAnimateShowChangeCellRenderer', valueFormatter: params => this.roundNumber(params.data.price_subtotal)},
      {headerName: 'Total', field: 'price_total', flex: 1, cellRenderer: 'agAnimateShowChangeCellRenderer', valueFormatter: params => this.roundNumber(params.data.price_total)}
      /* {headerName: 'Tax', field: 'tax_amount', flex: 1, valueFormatter: params => this.roundNumber(params.data.tax_amount)},
      {headerName: 'Total', field: 'price_total', flex: 1, valueFormatter: params => this.roundNumber(params.data.price_total)}*/
    ];


    this.getRowId = (params) => params.data.id;
  }

  getRowStyleFunc(params) {
    if (params.data && params.data.date_order === 'TOTAL') {
      return {'font-weight': 'bold'};
    }
  }

  async fetchData() {

    // await this.fetchProductStock();
    await this.fetchUserandBeats();
    //await this.fetchDeliveryPlans();
    await this.loadServerData();

    this.loadPartnersDataForEdit(() => {
      this.SUPPLIER_OBJ = this.supplierList.find(item => item.id === this.SUPPLIER_ID);
      if (this.SUPPLIER_OBJ) {
        // this.loadProducts(0, this.SUPPLIER_ID, this.SUPPLIER_OBJ['territory_id'], this.SUPPLIER_OBJ['sec_pricelist_id'], () => {
        this.pageReady = true;
        // });
      }
    });
  }

  fetchUserandBeats() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    this.apiService.post('/api/pwa_van_sales_connects/get_vans_for_delivery_plan', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.usersList = res.results.data;
          if (this.usersList && this.usersList.length > 0) {
            // this.deliveryPlanData.del_user_id = this.usersList[0].id;
            // this.deliveryPlanData.van_name = this.usersList[0].user_name;
            // this.deliveryPlanData.distributor_id = this.usersList[0].partner_id;

          } else {
            this.toastr.error("Vans list not found");
          }
        } else if (res.hasOwnProperty('results')) {
          this.toastr.error(res.results.msg);
        } else {
          this.toastr.error("User fetch failed");
        }
      });
  }

  loadServerData() {

    this.dataLoaded = false;


    this.apiService.post("/api/pwa_van_sales_connects/get_orders_for_delivery_plan", this.userContext)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.apiData = res.results.data;
            this.get_supplier_available_stocks(this.apiData, (err, result) => {
              if (err) {
                this.handleError(err);
              } else {
                this.productStock = result;
                this.rowData = this.prepareData(res.results.data);
                this.grid2RowData = this.preparePicklistData(res.results.data);
                this.dataLoaded = true;
                if (!this.deliveryPlanUpdated) {
                  this.menuWidth = document.getElementById("spanselect").offsetWidth.toString() + 'px';
                }
              }
            });
            this.checkIfDeliveryPlanAssigned();
            if (this.deliveryPlanUpdated) {
              this.fetchDeliveryPlanDetail();
            } else {
              this.fetchDeliveryPlans();
            }
            // document.getElementById("spanselectmenu").style.width = document.getElementById("spanselect").offsetWidth.toString() + 'px';
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));
  }

  checkIfDeliveryPlanAssigned() {
    const dpSet = new Set();
    if (this.apiData && this.apiData.length > 0) {
      this.apiData.forEach(row => {
        if(row.dp_id && row.dp_id > 0) {
          dpSet.add(row.dp_id);
        }
        // if (row.invoices && row.invoices.length > 0) {
        //   row.invoices.forEach(invoice => {
        //     if (invoice.inv_dp_id) {
        //       dpSet.add(invoice.inv_dp_id);
        //     }
        //   });
        // }
      });
    }
    if (dpSet) {
      if (dpSet.size > 1) {
        this.toastr.error('Order already assigned to anther VAN!');
      } else if (dpSet.size === 1) {
        this.deliveryPlanUpdated = true;
        //get iterator:
        const it = dpSet.values();
        //get first entry:
        this.deliveryPlanData.id = it.next().value;
      } else {
        this.deliveryPlanUpdated = false;
      }
    } else {
      this.deliveryPlanUpdated = false;
    }
  }

  fetchDeliveryPlans() {
    const inThis = this;
    this.dpList = [];
    this.deliveryPlanData.name = null;
    this.invoicecount = 0;
    this.quantity = 0;
    this.weight = 0.00;
    this.volume = 0.00;
    this.grandtotal = 0.0;
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.start_date = this.deliveryPlanData.date;
    paramObject.end_date = this.deliveryPlanData.date;
    this.apiService.post('/api/pwa_van_sales_connects/getDeliveryPlansWithOrders', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          inThis.dpList = res.results.data;
          if (inThis.dpList && inThis.dpList.length > 0) {
            inThis.dpList.forEach(dp => {
              const user = inThis.usersList.find(x => x.id === dp.del_user_id);
              if (user) {
                user['dp'] = dp.name;
                user['dp_id'] = dp.id;
              }
            });
            const deliveryplan = inThis.dpList.find(x => x.del_user_id === inThis.deliveryPlanData.del_user_id);
            if (deliveryplan) {
              inThis.deliveryPlanData = deliveryplan;


              if (this.deliveryPlanData.hasOwnProperty('vals_json')) {
                this.deliveryPlanData.vehicle = this.deliveryPlanData.vals_json.vehicle;
                this.deliveryPlanData.vehicle_type = this.deliveryPlanData.vals_json.vehicle_type;
                this.deliveryPlanData.del_contact = this.deliveryPlanData.vals_json.del_contact;
                this.deliveryPlanData.del_person = this.deliveryPlanData.vals_json.del_person;
              }


              if (inThis.deliveryPlanData.orders) {
                this.invoicecount = inThis.deliveryPlanData.orders.length;
                inThis.deliveryPlanData.orders.forEach(line => {
                  inThis.quantity += parseInt(line.qty, 10);
                  if (line.weight) {
                    inThis.weight += (parseFloat(line.weight) * inThis.quantity);
                  }
                  if (line.volume) {
                    inThis.volume += (parseFloat(line.volume) * inThis.quantity);
                  }
                  inThis.grandtotal += parseFloat(line.total);
                });
              }
            }
          } else {
            const olddate = this.deliveryPlanData.date;
            this.deliveryPlanData = {};
            this.deliveryPlanData.date = olddate;
            if (inThis.usersList) {
              inThis.usersList.forEach(user => {
                user['dp'] = null;
                user['dp_id'] = null;
              });
              this.deliveryPlanData.del_user_id = inThis.usersList[0].id;
              this.deliveryPlanData.van_name = inThis.usersList[0].user_name;
              this.deliveryPlanData.distributor_id = inThis.usersList[0].partner_id;
            }
          }
        } else if (res.hasOwnProperty('results')) {
          this.toastr.error(res.results.msg);
        } else {
          this.toastr.error("Delivery Plan fetch Failed");
        }
      });
  }

  fetchDeliveryPlanDetail() {
    const inThis = this;
    this.dpList = [];
    this.deliveryPlanData.name = null;
    this.invoicecount = 0;
    this.quantity = 0;
    this.weight = 0.00;
    this.volume = 0.00;
    this.grandtotal = 0.0;
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.deliveryPlanData;
    this.apiService.post('/api/pwa_van_sales_connects/getDeliveryPlanDetails', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.deliveryPlanData = res.results.data;

          if (this.deliveryPlanData.hasOwnProperty('vas_json')) {
            for (const key of this.deliveryPlanData.vals_json) {
              this.deliveryPlanData[key] = this.deliveryPlanData.vals_json[key];
            }
          }

          this.invoicecount = this.deliveryPlanData.orders.length;
          if (res.results.data.orders) {
            res.results.data.invoices.forEach(line => {
              this.quantity += parseInt(line.quantity, 10);
              if (line.weight) {
                this.weight += (parseFloat(line.weight) * this.quantity);
              }
              if (line.volume) {
                this.volume += (parseFloat(line.volume) * this.quantity);
              }
              this.grandtotal += parseFloat(line.total);
            });
          }
        } else if (res.hasOwnProperty('results')) {
          this.toastr.error(res.results.msg);
        } else {
          this.toastr.error("Delivery Plan fetch Failed");
        }
      });
  }

  prepareData(ordersList) {
    const ordersData = [];
    this.validInvoices = 0;
    // let invoicestatus = true;
    ordersList.forEach(order => {
      if (!order.territory_id || order.territory_id <= 0) {
        order['status'] = 'INVALID';
        order['err_msg'] = 'Invalid Customer Territory';
      } else if (!order.supplier_code || order.supplier_code === '') {
        order['status'] = 'INVALID';
        order['err_msg'] = 'Invalid Supplier Code';
      } else if (!order.distributor_id || order.distributor_id <= 0) {
        order['status'] = 'INVALID';
        order['err_msg'] = 'Invalid Supplier Id';
      } else if (!order.partner_id || order.partner_id <= 0) {
        order['status'] = 'INVALID';
        order['err_msg'] = 'Invalid Customer';
      } else {

        // let has_lines_to_invoice = false;
        // let stock_not_available = false;
        // if (order['lines']) {
        //   order['item_count'] = order['lines'].length;
        //   // tslint:disable-next-line:prefer-for-of
        //   for (let i = 0; i < order['lines'].length; i++) {
        //     if (!has_lines_to_invoice) {
        //       if (order['lines'][i].quantity * order['lines'][i].factor_inv > order['lines'][i].inv_base_qty) {
        //         has_lines_to_invoice = true;
        //         let stock = this.productStock.find(x => x.product_id === order['lines'][i].product_id);
        //         if (stock) {
        //           stock = stock.stock;
        //           if (order['lines'][i].quantity > stock) {
        //             stock_not_available = true;
        //             order['status'] = 'INVALID';
        //             if (!order['err_msg'] || order['err_msg'] === '') {
        //               order['err_msg'] = 'No stock available : ' + order['lines'][i].code;
        //             } else {
        //               order['err_msg'] = order['err_msg'] + ', ' + order['lines'][i].code;
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        // } else {
        //   order['item_count'] = 0;
        // }
        // if (!has_lines_to_invoice) {
        //   order['status'] = 'INVOICED';
        //   order['err_msg'] = 'No qty remaining to invoice';
        // } else {
        // if (!stock_not_available) {
        //   this.validInvoices++;
        //   order['status'] = 'VALID';
        //   order['err_msg'] = '';
        // }
        //   invoicestatus = false;
        // }
        // if (!order['scheme_discount']) {
        //   order['scheme_discount'] = 0;
        // }
        this.validInvoices++;
        order['status'] = 'VALID';
        order['err_msg'] = '';

        this.noOrdersToTransfer = false;
      }

      if (order['state'] === 'draft') {
        this.enable_confirm_orders_button = true;
      }

      ordersData.push(order);
    });
    //
    // if (invoicestatus) {
    //   this.noOrdersToTransfer = true;
    // } else {
    //   this.noOrdersToTransfer = false;
    // }
    return ordersData;
  }

  preparePicklistData(ordersList) {
    const pickListData = [];
    const uqProducts = new Set();
    const lineData = [];
    ordersList.forEach(order => {
      if (order.lines) {
        order.lines.forEach(line => {
          lineData.push(line);
          uqProducts.add(line.product_id);
        });
      }
    });

    if (uqProducts && uqProducts.size > 0) {
      for (const product_id of uqProducts) {
        const lines = lineData.filter(x => x.product_id === product_id);
        if (lines && lines.length > 0) {
          let stock = this.productStock.find(x => x.product_id === lines[0].product_id);
          if (stock) {
            stock = stock.stock;
          } else {
            stock = 0;
          }
          const productData = {
            id: lines[0].product_id,
            product_id: lines[0].product_id,
            product_name: lines[0].name,
            product_code: lines[0].code,
            uom_name: lines[0].uom_name,
            stock,
            quantity: 0,
            price_unit: parseFloat(lines[0].price_unit),
            scheme_discount: 0.00,
            price_subtotal: 0.00,
            price_tax: 0.00,
            tax_amount: 0.00,
            price_total: 0.00,
            orders: []
          };
          lines.forEach(line => {
            productData.quantity += parseInt(line.quantity, 10);
            productData.price_subtotal += parseFloat(line.price_subtotal);
            productData.price_tax += parseFloat(line.price_tax);
            productData.tax_amount += parseFloat(line.tax_amount);
            productData.scheme_discount += parseFloat(line.scheme_discount);
            productData.price_total += parseFloat(line.price_total);
            const orderdata = ordersList.find(x => x.id === line.order_id);
            productData.orders.push({
              line_id: line.line_id,
              name: orderdata.name,
              type: line.type,
              customer_name: orderdata.customer_name,
              quantity: line.quantity,
              uom_name: line.uom_name,
              price_unit: line.price_unit,
              scheme_discount: line.scheme_discount,
              price_subtotal: line.price_subtotal
            });
          });
          pickListData.push(productData);
        }
      }
    }
    return pickListData;
  }

  handleError(error: ErrorObject) {
    this.toastr.error(error.summary);
  }

  closeDialog() {
    this.transferCompleteEvent.emit(this.noOrdersToTransfer);
    this.dialog.closeAll();
  }

  /*BEGIN: Code similary to delivery Plan Component */

  onGrid2Ready(params) {
    this.grid2Options.api.showLoadingOverlay();
    this.grid2Api = params.api;
    this.grid2ColumnApi = params.columnApi;
    const noOfColumns = this.grid2ColumnApi.getAllColumns().length;
    params.api.setRowData(this.grid2RowData);

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (params.api) {
          params.api.sizeColumnsToFit();
          /*if (noOfColumns < 11 && screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi1.autoSizeAllColumns();
          }*/
        }
      });
    });

    this.grid2Api.sizeColumnsToFit();

    this.grid2Style = this.elRef.nativeElement.querySelector('#myGrid2');
  }

  changeActiveTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.activetab = tab;
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setColumnDefs(this.columnDefs);
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  generatePinnedBottomData() {
    // generate a row-data with null values
    const result = {};
    this.gridColumnApi.getAllGridColumns().forEach(item => {
      result[item.colId] = null;
    });
    return this.calculatePinnedBottomData(result);
  }

  calculatePinnedBottomData(target: any) {
    //console.log(target);
    //**list of columns fo aggregation**
    const columnsWithAggregation = ['item_count', 'amount_untaxed', 'scheme_discount', 'amount_total'];
    columnsWithAggregation.forEach(element => {
      console.log('element', element);
      this.gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
        //if(rowNode.index < 10){
        //console.log(rowNode);
        //}
        if (rowNode.data[element]) {
          target[element] += +Number(rowNode.data[element]).toFixed(2);
        }
      });
      if (target[element]) {
        target[element] = `${target[element]}`;
      }
    });
    target.date_order = 'TOTAL';
    //console.log(target);
    return target;
  }

  onFilterChanged($event) {
    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }


  quickSearch() {
    if (this.activetab === 'Orders') {
      this.gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
    } else {
      this.grid2Api.setQuickFilter(document.getElementById('filter-text-box1')['value']);
    }
  }

  printTable() {
    const inthis = this;
    let canvass;
    const api = this.gridOptions.api;

    this.setPrinterFriendly(api);

    setTimeout(async function() {
      if (inthis.activetab === 'Picklist') {
        const htmlresp1 = await html2canvas(document.querySelector("#printsectiondp1")).then(canvas => {
          //this.canvas = canvas.toDataURL();
          canvass = canvas;
          return this.canvas;
        });
      } else {
        const htmlresp1 = await html2canvas(document.querySelector("#printsectiondp2")).then(canvas => {
          //this.canvas = canvas.toDataURL();
          canvass = canvas;
          return this.canvas;
        });
      }
      const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      const position = 0;
      const imgProps = pdf.getImageProperties(canvass);
      const pdfWidth = pdf.internal.pageSize.getWidth() - 40;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(canvass, 'JPEG', 20, 20, pdfWidth, pdfHeight, '', 'FAST');
      await pdf.save(`dp.pdf`, {returnPromise: true});
      this.setNormal(api);
    }, 2000);
  }

  setPrinterFriendly(api) {
    let eGridDiv;
    if (this.activetab === 'Picklist') {
      eGridDiv = document.querySelector('#myGrid1');
    } else {
      eGridDiv = document.querySelector('#myGrid2');
    }

    eGridDiv['style'].width = '';
    eGridDiv['style'].height = '';
    api.setDomLayout('print');
  }

  /*END: Code similary to delivery Plan Component */

  onConfirmOrders() {

    const orderToProcess = this.rowData.filter(item => item.state === 'draft');
    const order_ids = [];

    orderToProcess.forEach(ord => {
      order_ids.push(ord.id);
    });

    if (order_ids.length > 0) {
      this.ConfirmOrders(order_ids, function(err, res) {

        if (res.hasOwnProperty('results') && res.results.status === 200) {
          if (res.results.hasOwnProperty('data') && res.results.data.length > 0) {

            const rowsToUpdate = [];
            res.results.data.forEach(res_id => {
              const ord = this.rowData.find(item => item.id === res_id);
              rowsToUpdate.push(this.updateImmutableObject(ord, {state: "sale"}));
            });

            this.gridApi.applyTransactionAsync({update: rowsToUpdate}, () => {
            });
          }
        } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
          this.handleError(ErrorHandler.getErrorObject(res.results.msg));
        } else {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        }
      });
    } else {
      this.toastr.success('All orders are already confirmed!');
      return;
    }
  }

  async onTransfer() {

    const orderToProcess = this.rowData.filter(item => item.status === 'VALID' && item.state === 'sale');
    if (orderToProcess.length === 0) {
      this.toastr.error('No Valid records to Process!');
      return;
    }
    this.invoice_ids = [];
    if (!this.deliveryPlanData || this.deliveryPlanData.id <= 0) {
      this.toastr.error('Delivery Plan not saved!');
      return;
    } else {

      const order_ids = [];
      orderToProcess.forEach(o => {
        order_ids.push(o.id);
      });

      const userData = {};
      userData['access_token'] = this.userContext.access_token;
      userData['order_ids'] = order_ids;
      userData['delivery_plan_id'] = this.deliveryPlanData.id;
      userData['distributor_id'] = this.deliveryPlanData.distributor_id;

      this.apiService.post('/api/pwa_van_sales_connects/update_van_and_delivery_plan', userData)
        .subscribe(res => {
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {

              const rowsToUpdate = [];
              res.results.data.forEach(res_id => {
                const ord = this.rowData.find(item => item.id === res_id['id']);
                rowsToUpdate.push(this.updateImmutableObject(ord, {status: "TRANSFERRED", invoice_no: res.results.data['id'], err_msg: ''}));
              });

              this.gridApi.applyTransactionAsync({update: rowsToUpdate}, () => {
              });

              this.toastr.success(rowsToUpdate.length + ' orders Transfered!');

              this.checkInvoiceStatus();


            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          error => {
            this.handleError(ErrorHandler.getErrorObject(error));
          });
    }



    //   for (const order of orderToProcess) {
    //     let rowValues = {status: "PROCESSING", invoice_no: '', err_msg: ''};
    //     const updatedGridRow = this.updateImmutableObject(order, rowValues);
    //     this.gridApi.applyTransactionAsync({update: [updatedGridRow]}, () => {
    //     });
    //
    //     //const invoice_obj = DMSBasePage.ConvertOrderToInvoice(order, this.USER.id, delivery_plan_id);
    //     // const eligible_schemes = this.filterEligibleSchemes(this.schemesMasterList, invoice_obj['partner_id']);
    //     // const s = new SchemeCalculator(invoice_obj['lines'], eligible_schemes, this.prodList, this.UOMList);
    //     //TODO: @nivil Please validate below line
    //     // invoice_obj['lines'] = s.computeSchemes();
    //
    //     TaxCalculator.CalculateLineTaxsAndTotal(order, this.TaxMasterList);
    //     // const t = new TaxCalculator(invoice_obj['lines'], this.TaxMasterList);
    //     // this.activeObject['tax_notes'] = t.CalculateLineTax();
    //     order['inv_dp_id'] = delivery_plan_id;
    //
    //     this.userContext.data = order;
    //
    //   if (orders_processed > 0) {
    //     this.toastr.success(orders_processed + ' Order Transferred!');
    //     this.checkInvoiceStatus();
    //   }
    // }
  }

  checkInvoiceStatus() {
    let invoicestatus = true;
    this.rowData.forEach(row => {
        if (row.status !== 'TRANSFERRED' && row.status !== 'INVALID') {
          invoicestatus = false;
        } else {

        }
      }
    );
    if (invoicestatus) {
      this.noOrdersToTransfer = true;
    } else {
      this.noOrdersToTransfer = false;
    }
  }

  processOrders() {

  }

  updateDeliveryPlan() {
    this.dpEdit = false;
    this.deliveryPlanData.name = this.deliveryPlanData.date + ' ' + this.deliveryPlanData.del_person;

    this.deliveryPlanData['vals_json'] = {
      vehicle: this.deliveryPlanData.vehicle,
      vehicle_type: this.deliveryPlanData.vehicle_type,
      del_contact: this.deliveryPlanData.del_contact,
      del_person: this.deliveryPlanData.del_person
    };

    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.deliveryPlanData;


    // paramObject.data.push);
    this.apiService.post('/api/pwa_van_sales_connects/save_van_delivery_plan', paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.deliveryPlanUpdated = true;
            if (res.results.data) {
              this.deliveryPlanData.id = res.results.data;
            }
            this.toastr.success('Delivery Plan updated Successfully');
          } else if (res.hasOwnProperty('results')) {
            this.toastr.error(res.results.msg);
          } else {
            this.toastr.error("Delivery Plan updation Failed");
          }
        },
        error => {
          console.log(error);
          this.toastr.error("Delivery Plan creation Failed");
        });
  }


  deepCopy(obj) {
    let copy;

    // Handle the 3 simple types, and null or undefined
    if (null === obj || "object" !== typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.deepCopy(obj[attr]);
        }
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  changeDate(dt) {
    this.deliveryPlanData.date = this.datePipe.transform(this.deliveryPlanData.date, 'yyyy-MM-dd');
    this.fetchDeliveryPlans();
  }

  selectTodaysDate() {
    this.deliveryPlanData.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  selectTommorowDate() {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    this.deliveryPlanData.date = this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  changeSelection() {
    if (this.dp_mode === 'create') {
      /* this.deliveryPlanData.beats = this.beat;
       const invoiceids = [];
       for (const key in this.invoiceData) {
         invoiceids.push(this.invoiceData[key].id);
       }
       this.deliveryPlanData.type = 'S';
       this.deliveryPlanData.invoices = invoiceids;*/
    } else {
      if (this.dpList && this.dpList.length > 0) {
        this.deliveryPlanData = this.dpList[0];
      }
    }
  }

  populateData() {
    this.deliveryPlanData = this.dpList.find(existed => existed.name = this.deliveryPlanData.name);
    this.invoicecount = this.deliveryPlanData.invoices.length;
  }


  //
  // async fetchProductStock() {
  //   const paramObject: any = {};
  //   paramObject.access_token = localStorage.getItem('resfreshToken');
  //   const res = await this.apiService.postPromise('/api/pwa_dms_connects/getDistributorProductStock', paramObject);
  //   if (res && res.hasOwnProperty('results') && (res.results.status === 200)) {
  //     this.productStock = res.results.data;
  //   } else if (res && res.hasOwnProperty('results')) {
  //     this.toastr.error(res.results.msg);
  //   } else {
  //     this.toastr.error("Product fetch failed");
  //   }
  //   /*this.apiService.post('/api/pwa_dms_connects/getDistributorProductStock', paramObject)
  //     .subscribe(res => {
  //       console.log(res);
  //       if (res.hasOwnProperty('results') && (res.results.status === 200)) {
  //         this.productStock = res.results.data;
  //       } else if (res.hasOwnProperty('results')) {
  //         this.toastr.error(res.results.msg);
  //       } else {
  //         this.toastr.error("Product fetch failed");
  //       }
  //     });*/
  // }


  onQtyEdit(event, qty) {
    if (event.value !== qty) {
      this.quantityEdited = true;
      const orderrow = this.apiData.find(x => x.name === event.data.name);
      const line = orderrow.lines.find(x => x.line_id === event.data.line_id);
      line.quantity = parseInt(qty, 10);

      let order = JSON.parse(JSON.stringify(orderrow));

      this.loadProducts(order.partner_id, order.distributor_id, order.territory_id, order.pricelist_id, () => {

        const eligible_schemes = this.filterEligibleSchemes(this.schemesMasterList, order.partner_id);
        const s = new SchemeCalculator(order['lines'], eligible_schemes, this.prodList, this.UOMMasterList);
        //TODO: @nivil Please validate below line
        order['lines'] = s.computeSchemes();
        order = TaxCalculator.CalculateLineTaxsAndTotal(order, this.TaxMasterList);
        const found = order.lines.find(x => x.line_id === line.line_id);
        this.updateApiData(order, found);

        this.rowData = this.prepareData(this.apiData);
        const newdata = this.preparePicklistData(this.apiData);
        this.editedOrders.push(event.data.name);
        const selectedRow = event.context.params.data;
        const modifiedRowLine = newdata.find(x => x.product_name === selectedRow.product_name);
        const orderdata = this.reconstructOrderData(event.context.params.data, modifiedRowLine, line);
        setTimeout(() => {
          this.grid2Options.api.applyTransaction({update: [orderdata]});
        }, 300);

      });

    }
  }

  updateApiData(order, lineData) {
    const found = this.apiData.find(x => x.id === order.id);
    if (found) {
      found.amount_untaxed = order.amount_untaxed;
      found.amount_tax = order.amount_tax;
      found.amount_total = order.amount_total;
      found.scheme_discount = order.scheme_discount;
      found.lines.forEach(line => {
        if (line.line_id === lineData.line_id) {
          line.price_tax = lineData.price_tax;
          line.price_subtotal = lineData.price_subtotal;
          line.scheme_discount = lineData.scheme_discount;
          line.price_total = lineData.price_total;
        }
      });
    }
  }

  reconstructOrderData(order, modifieddata, line) {
    const oldqty = order.quantity;
    order.quantity = 0;
    order.price_subtotal = modifieddata.price_subtotal;
    order.scheme_discount = modifieddata.scheme_discount;
    order.price_total = modifieddata.price_total;
    order.orders.forEach(obj => {
      order.quantity += parseInt(obj.quantity, 10);
      const found = modifieddata.orders.find(x => x.line_id === obj.line_id);
      obj.price_subtotal = found.price_subtotal;
      obj.scheme_discount = found.scheme_discount;
    });
    return order;
  }

  saveEditedOrder() {
    //do order processing
    this.quantityEdited = false;
    this.editedOrders = [];
  }

  editDeliveryPlan() {
    this.dpEdit = true;
    this.deliveryPlanUpdated = false;
  }

  onMatMenuSelect(item) {
    this.deliveryPlanData.del_user_id = item.id;
    this.deliveryPlanData.van_name = item.user_name;
    this.deliveryPlanData.del_person = item.user_name;
    this.deliveryPlanData.distributor_id = item.partner_id;

    if (item.dp_id) {
      const deliveryplan = this.dpList.find(x => x.id === item.dp_id);
      this.deliveryPlanData = deliveryplan;


      if (this.deliveryPlanData.hasOwnProperty('vals_json')) {
        this.deliveryPlanData.vehicle = this.deliveryPlanData.vals_json.vehicle;
        this.deliveryPlanData.vehicle_type = this.deliveryPlanData.vals_json.vehicle_type;
        this.deliveryPlanData.del_contact = this.deliveryPlanData.vals_json.del_contact;
        this.deliveryPlanData.del_person = this.deliveryPlanData.vals_json.del_person;
      }

      if (this.deliveryPlanData.orders) {
        this.deliveryPlanData.orders.forEach(line => {
          this.quantity += parseInt(line.qty, 10);
          if (line.weight) {
            this.weight += (parseFloat(line.weight) * this.quantity);
          }
          if (line.volume) {
            this.volume += (parseFloat(line.volume) * this.quantity);
          }
          this.grandtotal += parseFloat(line.total);
        });
      }

    }
  }

  goToDeliveryPlan() {
    this.dialog.closeAll();
    localStorage.setItem('dpid', this.deliveryPlanData.id);
    localStorage.setItem('dpindex', '0');
    // this.router.navigate([this.apiService.redirect_url + '/dms/delivery_plan_detail'], {
    //   state: {
    //     viewtype: 'detail',
    //     customerid: this.deliveryPlanData.id
    //   }
    // });

    this.router.navigate([this.apiService.redirect_url + '/vansales/delivery_plan_detail'], {
      relativeTo: this.route,
      queryParams: {id: this.deliveryPlanData.id}
    });

  }
  printInvoices() {
    this.printType = 'Invoice';
    this.getPrintDetails();
  }

  printPicklist() {
    this.printType = 'Picklist';
    this.getPrintDetails();
  }

  getPrintDetails() {
    const order_ids = [];
    this.gridApi.rowModel.rowsToDisplay.forEach(row => {
      if (row.data.status === 'INVOICED') {
        order_ids.push(row.data.id);
      }
    });
    const order_data = {
      access_token: this.userContext.access_token,
      order_id: order_ids
    };
    this.apiService.post('/api/pwa_connects/get_order_print_details', order_data)
      .subscribe(async res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          let orderdata = res.results.data;
          if (this.printType === 'Picklist') {
            const lines = this.preparePrintPicklistData(res.results.data);
            orderdata = orderdata[0];
            orderdata.sale_items = lines;
            orderdata.invoice_date = this.deliveryPlanData.date;
            orderdata.driver_name =  this.deliveryPlanData.del_person;
            orderdata.driver_contact =  this.deliveryPlanData.del_contact;
            orderdata = [orderdata];
            this.all_orders = orderdata;
          } else {
            await this.processPrintDetails(orderdata);
          }
          console.log("test");
          console.log(this.printType);
          const el: HTMLElement = this.interPrint.nativeElement;
          el.click();
        }
      });
  }

  preparePrintPicklistData(ordersList) {
    const pickListData = [];
    const uqProducts = new Set();
    const lineData = [];
    ordersList.forEach(order => {
      if (order.sale_items) {
        order.sale_items.forEach(line => {
          lineData.push(line);
          uqProducts.add(line.product_id);
        });
      }
    });

    if (uqProducts && uqProducts.size > 0) {
      for (const product_id of uqProducts) {
        const lines = lineData.filter(x => x.product_id === product_id);
        if (lines && lines.length > 0) {
          const productData = {
            product_id: lines[0].product_id,
            product_name: lines[0].product_name,
            product_uom: lines[0].product_uom,
            hsn: lines[0].hsn,
            unit_price: parseFloat(lines[0].unit_price),
            abs_qty: 0,
            amount_total: 0.00,
          };
          lines.forEach(line => {
            productData.abs_qty += parseInt(line.qty, 10);
            productData.amount_total += parseFloat(line.amount_total);
          });
          pickListData.push(productData);
        }
      }
    }
    return pickListData;
  }

  async processPrintDetails(orderdata) {
    const inThis = this;
    let total_qty = 0;
    let total_discount = 0;
    let printDetails = {};
    const all_orders = [];
    for (const data of orderdata) {
      // res['results'].data.forEach(async data => {
      total_qty = 0;
      printDetails = data;
      const tax_breaks = [];
      const taxnotes = [];
      printDetails['label_config'] = {};
      const labels = JSON.parse(localStorage.getItem('custom_labels'));
      if (labels) {
        labels.forEach(lab => {
          printDetails['label_config'][lab.label] = lab.display;
        });
      } else {
        printDetails['label_config']['PrimaryCurrency'] = '';
        printDetails['label_config']['SecondaryCurrency'] = '';
        printDetails['label_config']['SupplierLabel'] = 'Supplier';
        printDetails['label_config']['TaxLabel'] = 'GST';
      }
      if (typeof printDetails['tax_notes'] === 'string') {
        printDetails['tax_notes'] = JSON.parse(printDetails['tax_notes']);
      }
      if (printDetails['tax_notes']) {
        Object.keys(printDetails['tax_notes']).forEach(each_tax => {
          tax_breaks.push({
            name: each_tax,
            tax: printDetails['tax_notes'][each_tax].toFixed(inThis.apiService.decimalPolicy)
          });
          taxnotes.push(each_tax);
        });
      }
      printDetails['tax_breakup'] = tax_breaks;
      if (printDetails['partner_id'] === printDetails['partner_shipping_id']) {
        printDetails['customer_skip'] = true;
      } else {
        printDetails['customer_skip'] = false;
      }
      printDetails['print_text'] = inThis.domSanitizer.bypassSecurityTrustHtml(printDetails['print_text']);
      let rate = 0;
      let spli;
      printDetails['sale_items'].forEach(sale_item => {
        sale_item['label'] = '[' + sale_item.type + ']';
        const breaks = [];
        sale_item['new_tax'] = 0;
        if (sale_item['tax_notes']) {
          sale_item['tax_notes'] = JSON.parse(sale_item['tax_notes']);
          if (typeof sale_item['tax_notes'] === 'string' && sale_item['tax_notes'] !== "") {
            sale_item['tax_notes'] = JSON.parse(sale_item['tax_notes']);
          }
        }
        if (sale_item['tax_notes'] && sale_item['tax_notes'].constructor === Object) {
          Object.keys(sale_item['tax_notes']).forEach(each_tax => {
            if (sale_item['tax_notes'][each_tax].includes('(')) {
              spli = sale_item['tax_notes'][each_tax].split('(');
              rate = spli[1].split('%')[0].split(')')[0];
              breaks.push({name: each_tax, tax: spli[0], rate});
            } else {
              if (sale_item.hasOwnProperty('tax')) {
                spli = sale_item['tax'].split('(');
                rate = spli[1].split('%')[0].split(')')[0];
              } else {
              }
              breaks.push({name: each_tax, tax: sale_item['tax_notes'][each_tax], rate});
            }

          });
        }
        sale_item['tax_notes'] = breaks;

        total_discount += parseFloat(sale_item.vps_dis);
        if (printDetails['d_status'] === 'delivered') {
          sale_item['abs_qty'] = Math.abs(sale_item['delivered_qty']);
          total_qty += parseInt(sale_item.delivered_qty, 0);
          sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.delivered_qty)) -
            (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
        } else {
          sale_item['abs_qty'] = Math.abs(sale_item['qty']);
          total_qty += parseInt(sale_item.qty, 0);
          sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.qty)) -
            (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
        }
        sale_item.unit_price = sale_item.unit_price.toFixed(inThis.apiService.decimalPolicy);
      });
      printDetails['total_qty'] = total_qty;
      printDetails['discount'] = total_discount.toFixed(inThis.apiService.decimalPolicy);
      if (printDetails['date_delivery']) {
        printDetails['invoice_date'] = inThis.datePipe.transform((new Date(printDetails['date_delivery'] + ' UTC')).toString(), 'dd/MM/yyyy');
      } else if (printDetails['date_delivered'] != null) {
        printDetails['invoice_date'] = inThis.datePipe.transform((new Date(printDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      }
      printDetails['order_date'] = inThis.datePipe.transform((new Date(printDetails['order_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      if (printDetails['date_delivered'] != null) {
        printDetails['date_delivered'] = inThis.datePipe.transform((new Date(printDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      }
      if (printDetails['logo']) {
        const resp = await inThis.apiService.fetchImageWait({
          access_token: inThis.userContext.access_token,
          data: [{store_fname: printDetails['logo'], container: "res_partner"}]
        });
        // res_data.subscribe(resp => {
        console.log(resp);
        if (resp['result'].length > 0) {
          printDetails['logo'] = inThis.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + resp['result'][0].bill_datas);
        }
        all_orders.push(printDetails);
      } else {
        all_orders.push(printDetails);
      }
      this.all_orders = all_orders;
      console.log(all_orders);
    }
  }
}
