import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {ApiService} from "../_services/api.service";

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  public refresh_token;


  constructor(private router: Router,
              private apiService: ApiService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    this.refresh_token = localStorage.getItem('resfreshToken');
    let url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {

    console.log('check_login:' + url);
    let url_route;
    if ((this.refresh_token !== null) && (this.refresh_token !== "null")) {
      // url_route = url.split('/')[1]; //live
      url_route = url.split('?')[0];
      url_route = url_route.split('/')[1]; //local
      console.log('check_login:' + url_route);
     /* if (url_route === 'pro_dc_list' || url_route === 'prolist') {
        if (localStorage.getItem('pro_head') === 'true') {
          return true;
        }
      }*/
      if (url_route === 'menu_detail') {
        return true;
      } else if (url_route === 'pro_dc_list') {
        if (localStorage.getItem('pro_head') === 'true') {
          return true;
        }
      } else if (url_route === 'prolist') {
        if (localStorage.getItem('pro_head') === 'true') {
          return true;
        }
      } else if (url_route === 'prolistv3') {
        if ((localStorage.getItem('pro_head') === 'true') && (localStorage.getItem('feature_pro_listV3') === 'true')) {
          return true;
        }
      } else if (url_route === 'prolistv4') {
        if ((localStorage.getItem('pro_head') === 'true') && (localStorage.getItem('feature_pro_listV4') === 'true')) {
          return true;
        }
      } else if (url_route === 'boards' || url_route === 'dashboard' || url_route === 'mtsalesdc' || url_route === 'mtoutsalesdc' ||
        url_route === 'po' || url_route === 'po2' || url_route === 'cd' || url_route === 'dash') {
        return true;
      } else if (url_route === 'eod-dclist' || url_route === 'eod-vanlist' || url_route === 'eod' || url_route === 'vantransfers') {
        if (localStorage.getItem('dc_head') === 'true') {
          return true;
        }
      } else if (url_route === 'po_po' || url_route === 'new_po' || url_route === 'receipts' || url_route === 'returns'
      ) {
        if (localStorage.getItem('po_module') === 'true') {
          return true;
        }
      } else if (url_route === 'orders' || url_route === 'new_order' || url_route === 'order_invoices' ||
        url_route === 'new_invoice' || url_route === 'dms_payments' ||
        url_route === 'mystock' || url_route === 'delivery_plan' || url_route === 'return_invoice_list' || url_route === 'return_invoice')  {
        if (localStorage.getItem('dms_module') === 'true') {
          return true;
        }
        // tslint:disable-next-line:triple-equals
      } else if (url_route === 'retailer_list') {
        if (localStorage.getItem('module_distributors') === 'true') {
          return true;
        }
      } else if (url_route === 'expense') {
        return true;
      } else if (url_route === 'sales') {
        if (localStorage.getItem('sales_head') === 'true') {
          return true;
        }
      } else if (url_route === 'rawsalereport') {
        if (localStorage.getItem('dc_report') === 'true') {
          return true;
        }
      } else if (url_route === 'payment_approval_report') {
        if (localStorage.getItem('cheque_approval') === 'true') {
          return true;
        }
      } else if (url_route === 'order_aging_report') {
        if (localStorage.getItem('payment_ageing') === 'true') {
          return true;
        }
      } else if (url_route === 'outlet_overdue_report') {
        if (localStorage.getItem('outlet_overdue') === 'true') {
          return true;
        }
      } else if (url_route === 'stockinhand') {
        if (localStorage.getItem('stockhand_module') === 'true') {
          return true;
        }
      } else if (url_route === 'einvoice') {
        if (localStorage.getItem('einvoice_report_module') === 'true') {
          return true;
        }
      } else if (url_route === 'settlesalereport') {
        if (localStorage.getItem('team_report') === 'true') {
          return true;
        }
      } else if (url_route === 'usercollectionreport') {
        if (localStorage.getItem('user_report') === 'true') {
          return true;
        }
      } else if (url_route === 'loadinreport') {
        if (localStorage.getItem('loadin_report') === 'true') {
          return true;
        }
      } else if (url_route === 'dailycollection') {
        if (localStorage.getItem('daily_collection') === 'true') {
          return true;
        }
      } else if (url_route === 'vanreport') {
        if (localStorage.getItem('van_report') === 'true') {
          return true;
        }
      } else if (url_route === 'btlreport') {
        if (localStorage.getItem('btl_report') === 'true') {
          return true;
        }
      } else if (url_route === 'cancelreport') {
        if (localStorage.getItem('cancel_report') === 'true') {
          return true;
        }
      } else if (url_route === 'schemereport') {
        if (localStorage.getItem('scheme_report') === 'true') {
          return true;
        }
      } else if (url_route === 'pricelistreport') {
        if (localStorage.getItem('pricelist_report') === 'true') {
          return true;
        }
      } else if (url_route === 'outletdumpreport') {
        if (localStorage.getItem('outlet_report') === 'true') {
          return true;
        }
      } else if (url_route === 'livestockreport' || url_route === 'stock_log_entry' || url_route === 'cost_log_entry') {
        if (localStorage.getItem('livestock_report') === 'true') {
          return true;
        }
      } else if (url_route === 'reports' || url_route === 'logs' || url_route === 'credit_notes') {
        return true;
      } else if (url_route === 'prodcollectionreport') {
        if (localStorage.getItem('prod_report') === 'true') {
          return true;
        }
      } else if (url_route === 'salereturnreport') {
        if (localStorage.getItem('return_report') === 'true') {
          return true;
        }
      } else if (url_route === 'growthreport') {
        if (localStorage.getItem('growth_report') === 'true') {
          return true;
        }
      } else if (url_route === 'beatreport') {
        if (localStorage.getItem('beat_report') === 'true') {
          return true;
        }
      } else if (url_route === 'tireport') {
        if (localStorage.getItem('ti_report') === 'true') {
          return true;
        }
      } else if (url_route === 'outaggreport') {
        if (localStorage.getItem('outlet_agg_report') === 'true') {
          return true;
        }
      } else if (url_route === 'outaggprodreport') {
        if (localStorage.getItem('outlet_prod_report') === 'true') {
          return true;
        }
      } else if (url_route === 'reports') {
        const reportList = JSON.parse(localStorage.getItem('reports_list'));
        const urlRoute2 = url.split('/')[3];

        if (reportList[urlRoute2]) {
          return true;
        }
      } else if (url_route === 'task') {
        return true;
      } else if (url_route === 'task_planner') {
        if (localStorage.getItem('feature_task_planner') === 'true') {
          return true;
        }
      } else if (url_route === 'user_tasks') {
        return true;
      } else if (url_route === 'dms') {
        return true;
      } else if (url_route === 'vansales') {
        return true;
      } else if (url_route === 'retail_audit') {
        return true;
      } else if (url_route === 'assets') {
        return true;
      } else if (url_route === 'customers') {
        return true;
      } else if (url_route === 'influencers') {
        return true;
      }  else if (url_route === 'products') {
        return true;
      } else if (url_route === 'users') {
        return true;
      } else if (url_route === 'retail_survey') {
        return true;
      } else if (url_route === 'market_intelligence_report') {
        return true;
      } else if (url_route === 'market_intelligence_summary_report') {
        return true;
      } else if (url_route === 'retail_audit_report') {
        return true;
      } else if (url_route === 'retail_audit_distribution_report') {
        return true;
      } else if (url_route === 'user_survey') {
        return true;
      } else if (url_route === 'product_ageing_report') {
        return true;
      }  else if (url_route === 'beats') {
        return true;
      } else if (url_route === 'distributor_audit') {
        return true;
      } else if (url_route === 'survey_detail') {
        return true;
      } else if (url_route === 'claims') {
        return true;
      } else if (url_route === 'master') {
        if (localStorage.getItem('master_module') === 'true') {
          return true;
        }
      } else if (url_route === 'vansale_credit_notes') {
        if (localStorage.getItem('feature_credit_notes') === 'true') {
          return true;
        }
      } else if (url_route === 'service') {
        if (localStorage.getItem('feature_service') === 'true') {
          return true;
        }
      } else if (url_route === 'gstr') {
        if (localStorage.getItem('feature_gstr') === 'true') {
          return true;
        }
      } else if (url_route === 'daily_user_tracking') {
        if (localStorage.getItem('feature_tracking') === 'true') {
          return true;
        }
      } else if (url_route === 'monthly_user_tracking') {
        if (localStorage.getItem('feature_tracking') === 'true') {
          return true;
        }
      } else if (url_route === 'daywise_tracking') {
        if (localStorage.getItem('feature_tracking') === 'true') {
          return true;
        }
      }
    }

    this.router.navigate([this.apiService.redirect_url + "/pagenotfound"]);
    return false;
  }
}
