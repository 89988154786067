import {ElementRef, OnInit} from "@angular/core";
// @ts-ignore
import {IDetailPage} from "./iDetailPage";
import {LocalforageService} from "../_services/localforage.service";
import {ApiService} from "../_services/api.service";
import {ErrorHandler} from "../error/error-handler";
import {ErrorObject} from "../error/error-model";
import {SharedService} from "../_services/shared_service";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {DomSanitizer} from "@angular/platform-browser";
import {AppComponent} from "../app.component";

export abstract class  DetailPageBase implements IDetailPage, OnInit {

  public _Detail_GET_URL = "";
  public _Detail_SAVE_URL = "";


  public userContext = {
    access_token: null,
    data: {
      id: 0
    }
  };

  public activeID = 0;
  public activeObject: any = {};
  public referanceObject: any = {};
  public USER: any = {};
  public queryParams = {};

  public editMode = false; //ID > 0 and edit is clicked
  public newMode = false; //ID = 0
  public pageReady = false;


  public active_ids: any = {};
  public rowIndex = 0;
  public listLength = 0;

  protected matAuthorizedActionMenuItems = [];
  protected matActionMenuItemDeclaration = [];

  // Grid Variables
  preloader: any;
  emptyState: any;
  errorObject: ErrorObject;
  errorExists = false;
  dataLoaded: any;
  gridOptions: GridOptions;
  styleGrid: any;
  columnDefs = [];
  frameworkComponents: any = {};
  context: any;
  rowData = [];
  public gridColumnApi: any;
  public gridApi: any;
  public pinnedParams = [];
  public math = Math;
  showDetails = false;
  showErrors = false;
  totalDataCount = 0;
  // cartItems = [];

  static get_current_period_id(date) {
    let period_id;
    const jcData = JSON.parse(localStorage.getItem('jc_periods'));
    if (jcData) {
      for (let i = 0; i < jcData.length; i++) {
        if (new Date(date) >= new Date(jcData[i].start_date) && new Date(date) <= new Date(jcData[i].end_date)) {
          period_id =  jcData[i].id;
          i = jcData.length;
          break;
        }
      }
    }
    return period_id;
  }

  static generate_mid(object_id) {
    const USER = localStorage.getItem('user');
    if (object_id) {
      return USER['id'] + '_' +  object_id + '_' + Date.now();
    } else {
      return USER['id'] + '_' + Date.now();
    }
  }

  constructor(protected apiService: ApiService,
              public sharedService: SharedService,
              public elRef: ElementRef,
              public datePipe: DatePipe,
              protected _localCacheService: LocalforageService,
              protected toastr: ToastrService,
              protected route: ActivatedRoute,
              public dialog: MatDialog,
              public router: Router,
              public domSanitizer: DomSanitizer,
              public appComponent: AppComponent) {

    this.USER = localStorage.getItem('user');
    this.active_ids = JSON.parse(localStorage.getItem('active_ids'));
    this.activeID = JSON.parse(localStorage.getItem('activeID'));
    this.context = {componentParent: this};

    this.userContext.access_token = localStorage.getItem('resfreshToken');
    this.gridOptions = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      suppressRowClickSelection: true,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: false,
        sortable: false,
        resizable: true,
        enableRowGroup: false,
        menuTabs: []
      },
    } as GridOptions;
  }

  readPageParms(callback) {
    this.route.queryParamMap
      .subscribe(params => {
        this.activeID = Number(params.get('id'));
        this.queryParams = params;
        if (this.active_ids) {
          this.rowIndex = this.active_ids.indexOf(this.activeID);
          this.listLength = this.active_ids.length;
        }

        return callback(params);
      });
  }

  setTitle(title: string) {
    this.sharedService.setHeader(title);
  }

  checkAccessControl() {

  }

  loadActiveObject(callback) {
    this.userContext.data.id = this.activeID;
    if (this.activeID > 0 && this._Detail_GET_URL !== '') {

      this.newMode = false;
      this.editMode = false;

      this.apiService.post(this._Detail_GET_URL, this.userContext)
        .subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {
              this.activeObject = res.results.data;
              this.referanceObject = res.results.data; //Store original data for compare and discard changes

            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
            return callback();
          },
          error => {
            this.handleError(ErrorHandler.getErrorObject(error));
            return callback();
          });
    } else {

      this.newMode = true;
      this.editMode = true;
      this.activeObject = {};
      this.activeObject['id'] = 0;
      return callback();
      //  TODO: On Callback load default values
    }
  }

  async GetObject(apiURL, id) {
    this.userContext.data.id = id;
    const res = await this.apiService.postPromise(apiURL, this.userContext);
    if (res && res.results.status === 200) {
      return res.results.data;
    } else {
      this.toastr.error('Error fetching customer master');
    }
  }

  loadPage() {

  }

  getAuthorizedActions() {
    this.matAuthorizedActionMenuItems = [];
    this.matActionMenuItemDeclaration.forEach((actionItem) => {
      if (actionItem.feature === undefined) {
        this.matAuthorizedActionMenuItems.push(actionItem);
      } else {
        this._localCacheService.getAccessControlList().then(list => {
          if (list[actionItem.feature]) {
            this.matAuthorizedActionMenuItems.push(actionItem);
          }
        });
      }
    });
  }

  saveDetailPage(callback) {
    if (this._Detail_SAVE_URL !== '') {
      this.userContext.data = this.activeObject;
      this.apiService.post(this._Detail_SAVE_URL, this.userContext, )
        .subscribe(res => {
            if (res.hasOwnProperty('results') && res.results.status === 200) {
              this.preloader = false;
              this.dataLoaded = true;

              if (res.results.hasOwnProperty('data') && res.results.data.hasOwnProperty('id')) {
                this.activeID = res.results.data['id'];
                this.activeObject.id = res.results.data['id'];
                this.toastr.success('Record Saved! : ' + this.activeID);
              } else {
                this.toastr.success('Record Saved!');
              }

            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
            return callback(null, res);
          },
          error => {
            this.handleError(ErrorHandler.getErrorObject(error));
            return callback(error, null);
        });
    } else {
      this.handleError(ErrorHandler.getErrorObject('Invalid Config'));
      return callback('error', null);
    }
  }

  saveSelectedField(fieldsToSave, callback) {
    if (this._Detail_SAVE_URL !== '') {
      this.userContext.data = this.updateImmutableObject(this.referanceObject, fieldsToSave);
      this.apiService.post(this._Detail_SAVE_URL, this.userContext, )
        .subscribe(res => {
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {
              this.preloader = false;
              this.dataLoaded = true;

              this.activeID = res['id'];
              this.activeObject.id = res['id'];
            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
            return callback();
          },
          error => {
            this.handleError(ErrorHandler.getErrorObject(error));
            return callback();
          });
    } else {
      this.handleError(ErrorHandler.getErrorObject('Invalid Config'));
      return callback();
    }
  }

  updateImmutableObject(original: any, newValues: any) {
    // start with new object
    const newObject: any = {};
    // copy in the old values
    Object.keys(original).forEach(key => {
      newObject[key] = original[key];
    });
    // now override with the new values
    Object.keys(newValues).forEach(key => {
      newObject[key] = newValues[key];
    });
    return newObject;
  }

  moveNext() {

    const curIndex = this.active_ids.indexOf(this.activeID);
    if (curIndex >= 0 && curIndex < (this.active_ids.length - 1)) {
      this.rowIndex = curIndex + 1;
      this.activeID = this.active_ids[this.rowIndex];
      this.loadPage();
    }
  }

  movePrevious() {
    const curIndex = this.active_ids.indexOf(this.activeID);
    if (curIndex > 0) {
      this.rowIndex = curIndex - 1;
      this.activeID = this.active_ids[this.rowIndex];
      this.loadPage();
    }
  }

  ngOnInit(): void {
    this.checkAccessControl();
  }

  public handleError(error: ErrorObject) {
    // this.preloader = false;
    // this.emptyState = false;
    // this.dataLoaded = false;
    // this.errorExists = true;
    // this.errorObject = error;
    this.pageReady = true;
    this.toastr.error(error.summary);
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setColumnDefs(this.columnDefs);
    params.api.setRowData(this.rowData);
    this.preloader = false;
    this.gridApi.setDomLayout('autoHeight');
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    /*if (this.editMode) {
      params.api.startEditingCell( { rowIndex: 0, colKey: 'quantity'});
    }*/
  }

  configureGrid() {

  }

  configureData() {

  }



  get_mid(object_id) {
    const USER = JSON.parse(localStorage.getItem('user'));
    if (object_id) {
      return USER['id'] + '_' +  object_id + '_' + Date.now();
    } else {
      return USER['id'] + '_' + Date.now();
    }
  }

  formatNumber(number) {
    if (number) {
      number = parseFloat(number).toFixed(this.apiService.decimalPolicy);
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return '';
    }
  }

  roundNumber(number) {
    if (number) {
      number = parseFloat(number).toFixed(this.apiService.decimalPolicy);
      return parseFloat(number);
    } else {
      return '';
    }
  }

  convertExcelStringToDate(item) {
    let splitchar = '/';
    if (typeof item === 'number') {
      item = this.ExcelDateToJSDate(item);
      item = this.datePipe.transform(new Date(item), 'yyyy-MM-dd');
      return item;
    } else {
      if (item.includes('/')) {
        splitchar = '/';
      } else if (item.includes('-')) {
        splitchar = '-';
      } else {
        return '';
      }
    }
    const dat = item.split(splitchar);
    if (dat && dat.length === 3) {
      if (dat[0].length === 3) {
        return this.datePipe.transform(new Date(dat[0] + '-' + dat[1] + '-' + dat[2] ), 'yyyy-MM-dd');
      } else {
        return this.datePipe.transform(new Date(dat[2] + '-' + dat[1] + '-' + dat[0] ), 'yyyy-MM-dd');
      }
    } else {
      return '';
    }
  }
  ExcelDateToJSDate(serial) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;

    total_seconds -= seconds;
    return ((date_info.getMonth() + 1)).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
      + '/' + date_info.getDate().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
      + '/' + date_info.getFullYear();
  }

  detailsClick() {
    this.showDetails = !this.showDetails;
  }

  errorClick() {
    this.showErrors = !this.showErrors;
  }

  formatDateTime(value) {
    if (value) {
      return this.datePipe.transform((new Date(value + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
    } else {
      return '';
    }
  }

  configValueGetter(config_field, value_field) {
    return (params) => {
      if (params.data && params.data[config_field]) {
        try {
          let config_json;
          if (params.data[config_field].constructor !== Object) {
            config_json = JSON.parse(params.data[config_field]);
          } else {
            config_json = params.data[config_field];
          }
          if (config_json && config_json.hasOwnProperty(value_field)) {
            return config_json[value_field];
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }
}
