<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div class="search-list row">
        <div class="col-md-12 pad0">
          <div class="col-md-5 pad0">
            <div style="display: inline">

            </div>
          </div>
          <div class="col-md-7 pad0">
            <div class="filters">
              <ul class="list-unstyled">
                <li style="margin-left:5px;margin-right:5px !important;" class="margLR">
                  <app-components-searchablefilter [containerStyle]="'custom2'" [searchableFilter]="searchableFilter"
                                                   (onsetSearchableFilter)="setSearchableFilter($event)"></app-components-searchablefilter>
                </li>
                <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                  <div class="date-picker1">
                    <span class="datepicker-icon"><img src="/assets/images/date.svg"></span>
                    <input [selectMode]="'single'" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt"
                           [owlDateTime]="dt" placeholder="Date">
                    <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                    <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                  </div>
                </li>
                <li style="margin-left:5px;margin-right:5px !important;" class="margLR">
                  <div style="display: inline-block;">
                    <button
                      class="btn confirm-blue-btn1"
                      style="height: 35px;width:45px;margin-left: 5px;margin-right:5px;margin-bottom:4px;font-size: 12px !important;padding: 8px 12px !important;border-radius: 5px;"
                      (click)="loadMapData()"
                    >GO
                    </button>
                  </div>
                </li>
                <li>
                  <div class="greywhitebox row centeralign">
                    <div [matMenuTriggerFor]="moredropwdown" class="menuIcon"
                         title="Legend" style="display: inline-block;margin-left: 5px;">
                      <span title="Legend"><img style="height: 14px;" src="/assets/icons/legend.svg"></span>
                      <mat-menu #moredropwdown="matMenu" >
                        <div style="padding: 15px;">
                          <ng-container *ngFor="let item of legendData; let i = index">
                            <div style="margin-bottom: 8px;">
                              <img style="margin-right: 10px;height: 15px;" src="{{item.image}}">
                              <span style="font-size: 12px; color: #5D5D5D; font-weight: 400;">{{item.name}}</span>
                            </div>
                          </ng-container>
                        </div>
                      </mat-menu>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="map" id="map1"
           style="margin-top: 10px;border: 1px solid #D7DDE6;border-radius: 5px;height: calc(100vh - 157px);">
        <div style="height: 100%;">
          <div class="col-md-12 pad0" style="height: 100%;">
            <div class="col-md-9 pr-0">
              <div style="height: calc(100vh - 160px);">
                <agm-map *ngIf="dataLoaded" (mapReady)="onMapReady($event)"
                         [zoom]="zoom" [styles]="styles">
                  <ng-container *ngFor="let item of markerData; let i = index">
                    <agm-marker *ngIf="item.lat && item.long" [latitude]="item.lat" [longitude]="item.long"
                                [iconUrl]="getMarker(item)" (mouseOver)="onMouseOver(infoWindow, $event)"
                                (mouseOut)="onMouseOut(infoWindow, $event)">
                      <agm-info-window #infoWindow
                                       [latitude]="item.lat"
                                       [longitude]="item.long">
                        <span *ngIf="item['icon'] === 'start_marker'" class="detailvaluebold2">Start</span>
                        <span *ngIf="item['icon'] === 'end_marker'" class="detailvaluebold2">End</span>
                        <span *ngIf="item.activity" class="detailvaluebold2">{{ item.activity }}</span>
                        <div *ngIf="item.activity" class="row centeralign" style="margin-top: 5px;">
                          <span class="detaillabel4">Type</span>
                          <span class="detailvalue3" style="margin-left: 5px;">{{ item.activity_type }}</span>

                          <span class="detaillabel4" style="margin-left: 20px;">Time</span>
                          <span class="detailvalue3" style="margin-left: 5px;">{{ item.time }}</span>
                        </div>
                        <div
                          *ngIf="!item.activity && (item['icon'] === 'start_marker' || item['icon'] === 'end_marker')"
                          class="row centeralign" style="margin-top: 5px;">
                          <span class="detailvalue3">{{ item.time }}</span>
                        </div>
                      </agm-info-window>
                    </agm-marker>
                  </ng-container>
                  <div *ngIf="showRetailerMarkers">
                    <ng-container *ngFor="let item of retailerData; let i = index">
                      <agm-marker *ngIf="item.lat && item.long" [latitude]="item.lat" [longitude]="item.long"
                                  [iconUrl]="getMarker(item)" (mouseOver)="onMouseOver(infoWindow, $event)"
                                  (mouseOut)="onMouseOut(infoWindow, $event)">
                        <agm-info-window #infoWindow
                                         [latitude]="item.lat"
                                         [longitude]="item.long">
                          <span class="detailvaluebold2">{{ item.ret_name }}</span>
                          <div class="row centeralign" style="margin-top: 5px;">
                            <span class="detaillabel4">Code</span>
                            <span class="detailvalue3" style="margin-left: 5px;">{{ item.ret_code }}</span>

                            <span class="detaillabel4" style="margin-left: 20px;">Type</span>
                            <span class="detailvalue3" style="margin-left: 5px;">{{ item.ret_type }}</span>
                          </div>
                        </agm-info-window>
                      </agm-marker>
                    </ng-container>
                  </div>
                  <div *ngIf="showAlertMarkers">
                    <ng-container *ngFor="let item of alertData; let i = index">
                      <agm-marker *ngIf="item.lat && item.long" [latitude]="item.lat" [longitude]="item.long"
                                  [iconUrl]="getMarker(item)" (mouseOver)="onMouseOver(infoWindow, $event)"
                                  (mouseOut)="onMouseOut(infoWindow, $event)">
                        <agm-info-window #infoWindow
                                         [latitude]="item.lat"
                                         [longitude]="item.long">
                          <div class="row centeralign">
                            <img style="height: 12px;margin-right: 10px;" src="/assets/icons/alert_orange.svg"/>
                            <span *ngIf="item.type === 'time_spent'" class="alertText">Spent more time than usual in one location</span>
                            <span *ngIf="item.type === 'distance'" class="alertText">Excessive distances traveled in a very short time</span>
                            <span *ngIf="item.type === 'speed'" class="alertText">Travel speeds that are unreasonably high (>100 km/h).</span>
                          </div>
                          <div class="row centeralign" style="margin-top: 5px;">
                            <span class="detailvalue3">{{ item.time }}</span>
                          </div>
                        </agm-info-window>
                      </agm-marker>
                    </ng-container>
                  </div>

                  <agm-polyline *ngIf="trackData && trackData.length > 0" [strokeColor]="'#0762AD'">
                    <ng-container *ngFor="let track of trackData">
                      <agm-polyline-point [latitude]="track.lat" [longitude]="track.long">
                      </agm-polyline-point>
                    </ng-container>
                  </agm-polyline>
                </agm-map>
              </div>

            </div>
            <div class="col-md-3 pl-0 pr-0">
              <div
                style="background: white;padding: 10px;height: calc(100vh - 160px); overflow-y: auto;overflow-x: hidden;">
                <div>
                  <div class="row centeralign">
                    <span class="detailvaluebold">{{ summaryData['user'] }}</span>
                    <div style="margin-left: auto;">
                      <div class="button-row">
                        <span (click)="movePrevious()"><i
                          [ngClass]="{'disabledspan': rowIndex === 0,
                                     'enabledspan': rowIndex >0}"
                          class="fal fa-arrow-circle-left fa-2x"></i></span>
                        <span
                          style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{ rowIndex + 1 }}
                          /{{ listLength }}</span>
                        <span (click)="moveNext()"><i
                          [ngClass]="{'disabledspan': rowIndex === listLength-1,
                               'enabledspan': rowIndex < listLength-1}"
                          class="fal fa-arrow-circle-right fa-2x"></i></span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4" style="display: flex">
                    <div style="flex: 1; margin-right: 10px;">
                      <div style="border-radius: 3px;padding: 10px;background: #F5F7F9;height: 55px;">
                        <div style="display: inline-block; margin-right: 5px;">
                          <img style="height: 16px;margin-bottom: 30px;" src="/assets/icons/beat.svg">
                        </div>
                        <div style="display: inline-block">
                          <label class="detaillabel4" style="display: block">Distance Travelled</label>
                          <span class="detailvaluebold2" style="color: #0762AD;">{{ totalDistance }} Kms</span>
                        </div>
                      </div>
                    </div>
                    <div style="flex: 1;">
                      <div style="border-radius: 3px;padding: 10px;background: #F5F7F9;height: 55px;">
                        <div style="display: inline-block; margin-right: 5px;">
                          <img style="height: 16px;margin-bottom: 30px;" src="/assets/icons/clock.svg">
                        </div>
                        <div style="display: inline-block">
                          <label class="detaillabel4" style="display: block">Total Time Spent</label>
                          <span class="detailvaluebold2" style="color: #0762AD;">{{ totalTime }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4" style="border-radius: 3px;padding: 10px;background: #F5F7F9;height: 200px;">
                    <span class="detailvaluebold">{{ chartHeader }}</span>
                    <div *ngIf="dataLoaded" style="height: 95%">
                      <ngx-charts-pie-chart
                        [scheme]="chartcolorScheme"
                        [results]="chartData"
                        [gradient]=true
                        [legend]=true
                        [legendTitle]="''"
                        [legendPosition]="'right'"
                        [labels]=false
                        [doughnut]=true
                      >
                        <ng-template #tooltipTemplate let-model="model">
                          <div style="padding: 4px;">{{ model.name}}</div>
                        </ng-template>
                      </ngx-charts-pie-chart>
                      <!-- <ag-charts-angular
                         [options]="options"
                       ></ag-charts-angular>-->
                    </div>
                  </div>
                  <div class="mt-4">
                    <ul style="list-style: disc;padding-left: 0px;">
                      <li class="detailli">
                        <span class="dialoglabel" style="margin-left: 12px;">Beat Jump </span>
                        <span class="detailvaluebold2" style="margin-left: 10px;">{{ beatJumpCount }} outlets</span>
                      </li>
                      <li class="detailli">
                        <span class="dialoglabel" style="margin-left: 12px;">Start Time </span>
                        <span class="detailvaluebold2" style="margin-left: 10px;">{{ startTime }}</span>
                      </li>
                      <li class="detailli">
                        <span class="dialoglabel" style="margin-left: 12px;">End Time </span>
                        <span class="detailvaluebold2" style="margin-left: 10px;">{{ endTime }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="mt-4" style="border-radius: 3px;padding: 10px;background: #F5F7F9;">
                    <div class="row centeralign" style="cursor: pointer" (click)="alertCardClick()">
                      <span style="color: #2D2D2D; font-size: 14px;font-weight: 600;">Alerts ({{ alertData.length }}
                        )</span>
                      <img *ngIf="!showAlertCard" style="margin-left: auto;height: 8px;"
                           src="/assets/images/down_arrow_black.svg"/>
                      <img *ngIf="showAlertCard" style="margin-left: auto;height: 8px;"
                           src="/assets/images/up_arrow.svg"/>
                    </div>
                    <div *ngIf="showAlertCard" style="margin-top: 15px;">
                      <div *ngIf="alertData && alertData.length > 0">
                        <ng-container *ngFor="let alert of alertData; let i = index">
                          <div class="row centeralign" style="margin-bottom: 10px;">
                            <img style="height: 12px;margin-right: 10px;" src="/assets/icons/alert_orange.svg"/>
                            <span *ngIf="alert.type === 'time_spent'" class="alertText">Spent more time than usual in one location</span>
                            <span *ngIf="alert.type === 'distance'" class="alertText">Excessive distances traveled in a very short time</span>
                            <span *ngIf="alert.type === 'speed'" class="alertText">Travel speeds that are unreasonably high (>100 km/h).</span>
                          </div>
                        </ng-container>
                        <div class="row centeralign" style="margin-bottom: 10px;">
                          <span class="detaillabel2">Remove Alert Points</span>
                          <div style="display: inline-block;margin-left: auto;">
                            <label class="switch">
                              <input
                                type="checkbox"
                                name="showAlert"
                                [(ngModel)]="showAlertMarkers"
                                #showAlert="ngModel"
                                [checked]="showAlertMarkers"

                              />
                              <span class="slider round"></span>
                            </label>
                          </div>

                        </div>
                        <div class="row centeralign" style="margin-bottom: 10px;">
                          <span class="detaillabel2">Remove Retailers</span>
                          <div style="display: inline-block;margin-left: auto;">
                            <label class="switch">
                              <input
                                type="checkbox"
                                name="showRetailer"
                                [(ngModel)]="showRetailerMarkers"
                                #showRetailer="ngModel"
                                [checked]="showRetailerMarkers"
                              />
                              <span class="slider round"></span>
                            </label>
                          </div>

                        </div>
                      </div>
                      <span *ngIf="!alertData || alertData.length === 0">No Alerts Available</span>
                    </div>
                  </div>

                  <div class="mt-4" style="border-radius: 3px;padding: 10px;background: #F5F7F9;">
                    <div class="row centeralign" style="cursor: pointer" (click)="activityCardClick()">
                      <span
                        style="color: #2D2D2D; font-size: 14px;font-weight: 600;">Activity ({{ activityData.length }}
                        )</span>
                      <img *ngIf="!showActivityCard" style="margin-left: auto;height: 8px;"
                           src="/assets/images/down_arrow_black.svg"/>
                      <img *ngIf="showActivityCard" style="margin-left: auto;height: 8px;"
                           src="/assets/images/up_arrow.svg"/>
                    </div>
                    <div *ngIf="showActivityCard" style="margin-top: 15px;">

                      <div class="timeline">
                        <ng-container *ngFor="let item of activityData; let i = index">
                          <div class="container right">
                            <span class="detaillabel4">{{ item.time }}</span>
                            <div style="display: inline" styleProp="margin-left:10px;">
                              <span *ngIf="!item.ret_id" class="detailvaluebold2"
                                    style="margin-left: 10px;">{{ item.activity_type }}</span>
                              <div *ngIf="item.ret_id" style="display: inline;margin-left: 10px;">
                                <span class="detailvaluebold2">{{ item.ret_name }}</span>
                                <div style="margin-top: 5px; margin-left: 50px;">
                                  <div class="row centeralign">
                                    <span class="detaillabel4">Code</span>
                                    <span class="detailvalue3" style="margin-left: 5px;">{{ item.ret_code }}</span>

                                    <span class="detaillabel4" style="margin-left: 20px;">Beat</span>
                                    <span class="detailvalue3" style="margin-left: 5px;">{{ item.ret_code }}</span>
                                  </div>
                                  <div *ngIf="item.activities" style="margin-top: 15px;">
                                    <ng-container *ngFor="let item of item.activities; let i = index">
                                      <div style="margin-top: 5px;" class="row centeralign">
                                        <span class="detaillabel4">{{ item.time }}</span>
                                        <span class="detailvalue3" style="margin-left: 5px;">{{ item.activity }}</span>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>

                      <!-- <ol class="ols steps" style="height: 500px;">
                         <ng-container *ngFor="let item of activityData; let i = index">
                           <li>
                             <div class="lidetail">
                               <span class="detaillabel4">{{item.time}}</span>
                               <div style="display: inline" styleProp="margin-left:10px;">
                                 <span *ngIf="!item.ret_id" class="detailvaluebold2">{{item.activity}}</span>
                                 <div *ngIf="item.ret_id">
                                   <span *ngIf="!item.ret_id" class="detailvaluebold2">{{item.ret_name}}</span>
                                   <div style="margin-top: 5px;" class="row centeralign">
                                     <span class="detaillabel4">Code</span>
                                     <span class="detailvalue3" style="margin-left: 5px;">{{item.ret_code}}</span>

                                     <span class="detaillabel4" style="margin-left: 20px;">Beat</span>
                                     <span class="detailvalue3" style="margin-left: 5px;">{{item.ret_code}}</span>
                                   </div>
                                   <div *ngIf="item.activities" style="margin-top: 15px;">
                                     <ng-container *ngFor="let item of item.activities; let i = index">
                                       <div style="margin-top: 5px;" class="row centeralign">
                                         <span class="detaillabel4">{{item.time}}</span>
                                         <span class="detailvalue3" style="margin-left: 5px;">{{item.activity}}</span>
                                       </div>
                                     </ng-container>
                                   </div>
                                 </div>
                               </div>
                             </div>

                           </li>
                         </ng-container>
                       </ol>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
