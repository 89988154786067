import {PageBase} from "../../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";
import {ErrorHandler} from "../../../error/error-handler";
import {ErrortooltipComponent} from "../../../errortooltip";
import {SaleOrderBulkUploadComponent} from "../../secondary/sale_order/sale_order_bulk_upload.component";
import {LoadOutUploadComponent} from "./loadout_upload.component";
import {PrintInvoiceComponent} from "../../../printInvoice.component";
import {PrintClickRenderer} from "../../../printClickRenderer.component";


@Component({
  selector: 'app-loadout-list',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class LoadOutListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'draft',
    values: [],
    show: true
  };

  matActionMenuItemDeclaration = [{name: 'Confirm Load-out'}, {name: 'Dispatch Load-out'}]; //, feature: 'action_invoice_create'

  ngOnInit(): void {

    this.showUpload = true;
    // this.customFilter.values = [
    //   {id: 'all', itemName: 'All'},
    //   {id: 'draft', itemName: 'Draft'},
    //   {id: 'sale', itemName: 'Confirmed'},
    //   {id: 'delivered', itemName: 'Delivered'},
    //   {id: 'Cancel', itemName: 'Cancelled'},
    // ];
    this.checkAndLoadPreviousSate();

    this.showCustomFilter = false;
    this.showActionButton = true;
    this.showDate = true;

    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.gridOptions.getRowStyle = this.getRowStyleFunc;
    this.frameworkComponents = {
      errortooltipComponent: ErrortooltipComponent
    };
    this.showPinnedFooter = true;
    this.columnsWithAggregation = ['amount_untaxed'];
    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();

    this.sharedService.setHeader('Load Outs');
  }

  getRowStyleFunc(params) {
    if (params.data && params.data.id === 'TOTAL') {
      return {'font-weight': 'bold'};
    }
  }

  configureGrid() {
    this.API_URL = '/api/pwa_van_sales_connects/get_load_outs';

    this.statusConfig.success = 'Dispatched';
    this.statusConfig.success1 = 'complete';
    this.statusConfig.error = 'cancel';
    this.statusConfig.new = 'Confirmed';
    this.statusConfig.new1 = 'started';
    this.statusConfig.warning = 'draft';
    this.statusConfig.warning1 = 'Not Created';
    this.columnDefs = [
      {headerName: "ID", field: "id", width: 25, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "Order No", field: "order_no", width: 80},
      {headerName: "Date", field: "date", width: 40},
      {headerName: "Code", field: "van_code", width: 50},
      {headerName: "Supplier", field: "sup_name", width: 70},
      {headerName: "VAN", field: "van", width: 70},
      {headerName: "Sub Total", field: "amount_untaxed", width: 50, aggFunc: 'sum', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: "Salesman", field: "salesman", width: 40},
      {headerName: "Order Status", field: "state", width: 40,
        valueGetter: params => {
          if (params.data && params.data.hasOwnProperty('d_status') && params.data.d_status === 'dispatched') {
              return 'Dispatched';
          } else if (params.data && params.data.hasOwnProperty('d_status') && params.data.d_status === 'received') {
            return 'Received';
          } else if (params.data && params.data.hasOwnProperty('d_status') && params.data.d_status === 'delivered') {
            return 'Delivered';
          } else {
            if (params.data && params.data.hasOwnProperty('state')) {
              if (params.data.state === 'sale') {
                return 'Confirmed';
              } else {
                return params.data.state;
              }
            }
          }
        }, cellRenderer: this.sharedService.statusIndicatorCellRenderer},
     /* {headerName: "Note", field: "note", width: 40},*/
      {headerName: "Supplier", field: "sup_name", width: 40, hide: true},
      {headerName: "Van Status", field: "vansale_status", width: 30, cellRenderer: this.sharedService.statusCellRenderer},
      {headerName: "Cluster", field: "cluster", width: 30},
      {headerName: "State", field: "geo_state", width: 30, hide: true},
      {
        headerName: 'PRINT', field: 'PRINT', pinned: 'right',
        headerComponent: PrintInvoiceComponent,
        cellRenderer: PrintClickRenderer, width: 50
      }
    ];
    this.getRowId = (params) => params.data.id;
  }

  printAll(rows) {
    this.appComponent.printAll(rows, 'id', '/api/pwa_connects/get_order_print_details', null);
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    if (column === 'PRINT') {
      this.appComponent.printAll([event.data], 'id', '/api/pwa_connects/get_order_print_details', null);
    } else {
      if (filteredRowData !== null && filteredRowData.length > 0) {
        const ids = [];
        filteredRowData.forEach(r => {
          ids.push(r.data.id);
        });
        localStorage.setItem('active_ids', JSON.stringify(ids));
        this.router.navigate([this.apiService.redirect_url + '/vansales/load_out'], {
          relativeTo: this.route,
          queryParams: {id: event.data.id}
        });
      }
    }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setColumnDefs(this.columnDefs);
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  checkAccessControl() {
    this.showCreate = true;
  }

  openCreateDialog() {

    localStorage.setItem('active_ids', JSON.stringify([]));
    this.router.navigate([this.apiService.redirect_url + '/vansales/load_out'], {
      relativeTo: this.route,
      queryParams: {id: 0}
    });
  }

  showUploadPopup() {
    // this.uploadSaleOrdersClick();
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
        }
      };
      const creatediaeref = this.dialog.open(LoadOutUploadComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
        }
      };
      const creatediaeref = this.dialog.open(LoadOutUploadComponent, config);
    }
  }
  //
  // uploadLoadOutClick() {
  //   const supplier_id = JSON.parse(localStorage.getItem('user')).partner_id;
  //   const config: MatDialogConfig = {
  //     maxWidth: '100vw',
  //     maxHeight: '100vh',
  //     height: '100%',
  //     width: '100%',
  //     panelClass: 'full-screen-modal',
  //     data: {
  //       upload_type: 'sales_order_upload_v1',
  //       title: "Upload Sales Order",
  //       message: "You are Sale Order ",
  //       active_id: supplier_id,
  //       instruction: "Column 1: Outlet_code <br> Column 2: action (ADD/REMOVE), Click Here to download .",
  //     },
  //   };
  //
  //   // const dialogRef = this.dialog.open(SaleOrderUploadComponent, config);
  //   // dialogRef.componentInstance.uploadData.subscribe(status => {
  //   //   if (status === 'success') {
  //   //     this.dialog.closeAll();
  //   //     window.location.reload();
  //   //   }
  //   // });
  // }


  onMenuItemSelected(menuitem) {
    const rows = this.gridApi.getSelectedRows();
    const supplier_ids = [];
    const supplier_territory_ids = [];
    if (rows === null || rows === undefined || rows.length === 0) {
      this.toastr.error('Please select at least 1 Order');
    } else if (rows.length > 100) {
      this.toastr.error('Please select a maximum of 100 order : ' + rows.length + ' selected');
    } else {
      const ids = [];

      if (menuitem.name === 'Create Invoice') {
        rows.forEach(r => {
          if (r.state === 'sale') {
            ids.push(r.id);
            if (!supplier_ids.includes(r.supplier_id)) {
              supplier_ids.push(r.supplier_id);
            }
            if (!supplier_territory_ids.includes(r.supplier_territory_id)) {
              supplier_territory_ids.push(r.supplier_territory_id);
            }
          }
        });

        if (ids.length > 0) {
          if (supplier_ids.length <= 0) {
            this.toastr.warning('Supplier id Missing');
          }
          if (supplier_ids.length > 1) {
            this.toastr.warning('More then One Supplier Selected');
          }
          if (supplier_territory_ids.length <= 0) {
            this.toastr.warning('Supplier Territory id Missing');
          }
          if (supplier_territory_ids.length > 1) {
            this.toastr.warning('More then One Supplier Territory Selected');
          }

          this.createBulkInvoiceClick(ids, supplier_ids[0], supplier_territory_ids[0]);
        } else {
          this.toastr.warning('Please select only CONFIRMED Orders');
        }
      } else if (menuitem.name === 'Confirm Order') {
        rows.forEach(r => {
          if (r.state === 'draft') {
            ids.push(r.id);
          }
          if (ids.length > 0) {
            this.confirmOrdersClick(ids);
          } else {
            this.toastr.warning('Please select only DRAFT Orders');
          }
        });
      }
    }
  }


  createBulkInvoiceClick(order_ids, supp_id, supp_territory_id) {
    const config: MatDialogConfig = {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        ids: order_ids,
        supplier_id: supp_id,
        supplier_territory_id: supp_territory_id
      },
    };
  }


  confirmOrdersClick(order_ids) {
    if (order_ids.length > 0) {
      const userData = {};
      userData['access_token'] = this.userData.access_token;
      userData['order_ids'] = order_ids;

      this.apiService.post('/api/pwa_dms_connects/confirm_orders', userData)
        .subscribe(res => {
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {

              const rowsToUpdate = [];
              res.results.data.forEach(res_id => {
                const ord = this.rowData.find(item => item.id === res_id['id']);
                rowsToUpdate.push(this.updateImmutableObject(ord, {state: res_id['state']}));
              });

              this.gridApi.applyTransactionAsync({update: rowsToUpdate}, () => {
              });

              this.toastr.success(rowsToUpdate.length + ' orders confirmed!');

            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          error => {
            this.handleError(ErrorHandler.getErrorObject(error));
          });

    } else {
      this.toastr.success('All orders are already confirmed!');
      return;
    }
  }

  onFilterChanged($event) {
    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }
}
